export default function extractMainDomain ( url: string ) {
  if ( !url.startsWith( "http://" ) && !url.startsWith( "https://" ) ) {
    url = `http://${url}`
  }
  const parsedUrl = new URL( url )
  const parts = parsedUrl.hostname.split( "." )
  if ( parts.length > 2 ) {
    return parts[ 1 ]
  }
  return parts[ 0 ]
}

function base64UrlDecode ( base64Url: any ) {
  // Add padding if needed and replace characters according to the JWT spec
  const base64 = base64Url.replace( /-/g, "+" ).replace( /_/g, "/" )
  // Decode base64 string
  const decoded = atob( base64 )
  // Convert the decoded string to a Uint8Array
  const uint8Array = new Uint8Array( decoded.length )
  for ( let i = 0; i < decoded.length; i += 1 ) {
    uint8Array[ i ] = decoded.charCodeAt( i )
  }
  return uint8Array
}

export function decodeJwt ( token: any ) {
  const parts = token.split( "." )
  if ( parts.length !== 3 ) {
    throw new Error( "Invalid JWT format" )
  }

  const header = JSON.parse( new TextDecoder().decode( base64UrlDecode( parts[ 0 ] ) ) )
  const payload = JSON.parse(
    new TextDecoder().decode( base64UrlDecode( parts[ 1 ] ) )
  )

  return {
    header,
    payload
  }
}

export const getFileExtension = ( content: any ) => {
  const extensionMap = {
    '.pdf': 'application/pdf',
    '.csv': 'text/csv',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
    '.tiff': 'image/tiff',
    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.xls': 'application/vnd.ms-excel',
    '.txt': 'text/plain',
  };

  for ( const [ ext, mime ] of Object.entries( extensionMap ) ) {
    if ( content.includes( ext ) ) {
      return mime
    }
  }
  return "application/octet-stream";
}

export const convertFileToBase64 = ( file: File ) => {
  return new Promise( ( resolve, reject ) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL( file )

    fileReader.onload = () => {
      resolve( fileReader.result )
    }

    fileReader.onerror = ( error ) => {
      reject( error )
    }
  } )
}

export const videoFormats = [
  "MP4",
  "AVI",
  "MOV",
  "WMV",
  "MKV",
  "FLV",
  "WebM",
  "MPEG"
];

