import styled from "styled-components";
import { Header } from "antd/es/layout/layout";
import { rgba } from "../../config/variable";

export const HeaderStyle = styled(Header)`
  &.ant-layout-header {
    background: ${(props) => props.theme.colordwhitedark};
    padding: 17px 25px 16px 12px;
    height: 84px;
    display: flex;
    gap: 47px;
    /* padding-inline: 16px; */
    box-shadow: 0px 2px 6px ${(props) => rgba(props.theme.colortileblack, 0.08)};
    line-height: 1;
    border-bottom: 1px solid ${(props) => props.theme.colorbluegreylight};
    .anticon-singlelogo {
      font-size: 50px;
    }
  }
  .badge-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    .presence-room {
      position: absolute;
      top: 27px;
      right: -3px;
      .anticon {
        font-size: 19px !important;
        background: ${(props) => props.theme.colordwhitedark};
        color: ${(props) => props.theme.colordwhitedark};
        border-radius: 100%;
      }
    }
    .drop-icon {
      color: ${(props) => props.theme.textcolordarkgrey};
    }
  }
`;
export const LogoStyle = styled.div``;
export const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const NavLeft = styled.div``;
export const NavCenter = styled.div`
  width: 320px;
  .ant-form-item {
    margin: 0;
    .ant-input-prefix {
      border-right: 1px solid ${(props) => props.theme.colorbluegreylight};
      border-radius: 2px;
      padding-right: 12px;
    }
    .ant-input {
      padding-left: 12px;
    }
  }
`;
export const NavRight = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  .buttonwrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .ant-btn {
      padding: 0;
      min-width: unset;
      .anticon {
        transform: none;
      }
    }
  }
  .userprofile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    max-height: 40px;
    min-width: 170px;
    .username {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    }
    .ant-avatar-lg {
      &.ant-avatar-icon {
        border-radius: 100%;
      }
    }
  }
`;
