/* eslint-disable */
export const xl = (content: any) => `
  @media (max-width: 1366px) {
    ${content};
  }
`;
export const xxl = (content: any) => `
  @media only screen and (min-width: 1366px) and (max-width: 1750px) {
    ${content};
  }
`;
export const rem = (pxValue: any) => {
  const ratio = 16; // Set according to the project configuration ratio
  let pxValues: any;
  if (Array.isArray(pxValue)) {
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    pxValue = pxValue[0];
  }
  // eslint-disable-next-line prefer-const
  pxValues = parseInt(pxValue, 10);
  return `${pxValues / ratio}rem`;
};
const hexToRgb = (hex: any) => {
  // http://stackoverflow.com/a/5624139
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  // eslint-disable-next-line no-param-reassign
  hex = hex?.replace(
    shorthandRegex,
    // eslint-disable-next-line func-names
    function (m: string, r: string, g: string, b: string) {
      return r + r + g + g + b + b;
    }
  );
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if(result){
    return {
      r: parseInt(result![1], 16),
      g: parseInt(result![2], 16),
      b: parseInt(result![3], 16),
    };
  }
};
export const rgba = (hex: string, alpha: number) => {
  const rgbColor = hexToRgb(hex);
  return `rgba(${rgbColor?.r}, ${rgbColor?.g}, ${rgbColor?.b}, ${alpha})`;
};
