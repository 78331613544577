export const allowedUcaasValues = new Set([
  "Super User",
  "Reseller",
  "Office Admin"
])
export const allowedPortingValues = new Set([
  "Office Manager",
  "Office Manager Read Only",
  "Reseller",
  "Reseller Read Only",
  "Super User",
  "Super User Read Only"
])
export const allowedSecureFaxValues = new Set([
  "Office Manager",
  "Office Manager Read Only",
  "Reseller",
  "Reseller Read Only",
  "Super User",
  "Super User Read Only"
])
