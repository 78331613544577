/* eslint-disable import/no-cycle */

/* eslint-disable @typescript-eslint/return-await */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useMemo } from "react"
import { useAppSelector } from "../../store/hooks"
// eslint-disable-next-line import/no-cycle
import { RootState } from "../../store/store"
import {
  registerDealsListGet,
  dealDataGet,
  dealDataUpdate,
  dealTimeLineDataGet,
  createPartnersDealAccountPost,
  IcreatePartnersAccountBody,
  createPartnersDealContactPost,
  IcreatePartnersContactBody,
  createPartnersDealPost,
  IcreatePartnersDealBody,
  IupdatePartnersDealBody,
  updatePartnersDealPut,
  IupdatePartnersAccountBody,
  updatePartnersAccountPut,
  IupdatePartnersContactBody,
  updatePartnersContactPut,
  partnerDetailsGet,
  partnerAccountGet,
  partnerAccountCreate,
  partnerAccountPut,
  partnerContactCreate,
  partnerContactsGet,
  partnerResourceGet,
  partnerResourceTag,
  partnerDeleteResourceTag,
  partnerUpdateResourceTag,
  partnerResourceOptions,
  partnerResourceFilterOptions,
  PartnerGetAssestData,
  updatePartnerDocument,
  uploadPartnerDocument,
  PartnerAssestDocument,
  assetMediaURLGet,
  assetDelete,
  partnerAccountDetailsGet,
  createPartnerAccounts,
  updatePartnersAccountUpdate,
  partnerContactsGetList,
  partnerResourceDeleteSingle,
  partnerSingleContactDetailsGet,
  getPartnerOnboardingDetails,
  getPartnerEmbededSigningUrl,
  partnerOnboarding,
  partnerLeadOnboarding,
  getPartnerDocumentStatus,
  getPartnerAccountOnboardingDetails,
  validatePartnerIdentifierPost,
  getPartnerLeadOnboardingDetails,
  getPartnerLeadDocumentStatus,
  getPartnerLeadEmbededSigningUrl,
  partnerLeadAccountGet,
  partnerLeadAccountCreate,
  leadAccountDetails,
  partnerLeadAccountPut
} from "./partnerService"

export interface IaccountDetails extends IcreatePartnersAccountBody {
  accounts_id: string
}

export interface IcontactDetails extends IcreatePartnersContactBody {
  contacts_id: string
}

export interface IpartnerAccountBody {
  account_id: string
  account_name: string
  email_domain: string
  website: string
  phone: string
  employees: string
  partner_account_type: string
}

interface DealPartnerDetails {
  partner_account_id: string
  partner_contact_id: string
  technology_service_distributor: string
  technology_service_distributor_contact: string
}

interface InitialState {
  dealPipelineData: any
  dealDataGet: any
  accountDetails: IaccountDetails | undefined
  contactDetails: IcontactDetails | undefined
  dealDetails: IcreatePartnersDealBody | undefined
  accountData: IpartnerAccountBody[]
  contactsData: []
  resourceData: any
  loading: boolean
  loadingPartnerOnboarding: boolean
  loadingAddPartnerOnboarding: boolean
  error: any
  resourceOption: any
  resourceCardData: any
  resourceDocument: any
  assetsData: any
  partnerOnboardingData: any
  isPartnerOnboarding: boolean
  dealPartnerDetails: DealPartnerDetails | undefined
}

/* ============================== Partner SLICE ============================== */

const initialState: InitialState = {
  dealPipelineData: {},
  dealDataGet: {},
  accountDetails: undefined,
  contactDetails: undefined,
  dealDetails: undefined,
  accountData: [],
  contactsData: [],
  resourceData: undefined,
  loading: false,
  loadingPartnerOnboarding: false,
  loadingAddPartnerOnboarding: false,
  error: null,
  resourceOption: undefined,
  resourceCardData: undefined,
  resourceDocument: undefined,
  assetsData: undefined,
  partnerOnboardingData: undefined,
  isPartnerOnboarding: false,
  dealPartnerDetails: undefined
}

export const getRegisteredDealsList = createAsyncThunk(
  "partner/registered-deals-list",
  async (params: any) => {
    return await registerDealsListGet(params)
  }
)

export const getPartnerDetails = createAsyncThunk(
  "partner/details",
  async () => {
    return await partnerDetailsGet()
  }
)

export const getDealData = createAsyncThunk(
  "partner/deal-data",
  async (params: any) => {
    return await dealDataGet(params)
  }
)

export const getPartnerOnboardingData = createAsyncThunk(
  "partner/onboarding-data",
  async (partner_account_id: string) => {
    return await getPartnerOnboardingDetails(partner_account_id)
  }
)

export const getPartnerLeadOnboardingData = createAsyncThunk(
  "partner/onboarding-lead-data",
  async (partner_lead_id: string) => {
    return await getPartnerLeadOnboardingDetails(partner_lead_id)
  }
)

export const getPartnerAccountOnboardingData = createAsyncThunk(
  "partner/onboarding-account-data",
  async (params: any) => {
    return await getPartnerAccountOnboardingDetails(params)
  }
)

export const getPartnerDocutmentStatusData = createAsyncThunk(
  "partner/onboarding-document-status",
  async ({
    partner_account_id,
    params
  }: {
    partner_account_id: string
    params: any
  }) => {
    return await getPartnerDocumentStatus(partner_account_id, { params })
  }
)

export const getPartnerLeadDocumentStatusData = createAsyncThunk(
  "partner/lead-document-status",
  async ({
    partner_lead_id,
    params
  }: {
    partner_lead_id: string
    params: any
  }) => {
    return await getPartnerLeadDocumentStatus(partner_lead_id, { params })
  }
)

export const getPartnerEmbededSigningData = createAsyncThunk(
  "partner/embeded-sigining-data",
  async ({
    partner_account_id,
    params
  }: {
    partner_account_id: string
    params: any
  }) => {
    return await getPartnerEmbededSigningUrl(partner_account_id, { params })
  }
)

export const getPartnerLeadEmbededSigningData = createAsyncThunk(
  "partner/lead-embeded-sigining-data",
  async ({
    partner_lead_id,
    params
  }: {
    partner_lead_id: string
    params: any
  }) => {
    return await getPartnerLeadEmbededSigningUrl(partner_lead_id, { params })
  }
)

export const getDealTimeData = createAsyncThunk(
  "partner/deal-timeline-data",
  async (params: any) => {
    return await dealTimeLineDataGet(params)
  }
)

export const UpdateDealData = createAsyncThunk(
  "partner/deal-data/update",
  async (params: any) => {
    return await dealDataUpdate(params)
  }
)

export const createPartnersDealAccount = createAsyncThunk(
  "/partners/create-account",
  async (params: IcreatePartnersAccountBody) => {
    return await createPartnersDealAccountPost(params)
  }
)

export const validatePartnerIdentifier = createAsyncThunk(
  "/partners/validate-identifier",
  async (body: { identifier: string; partner_id: number }) => {
    return await validatePartnerIdentifierPost(body)
  }
)

export const createPartnersDealContact = createAsyncThunk(
  "/partners/create-contact",
  // async ( params: IcreatePartnersContactBody ) => {
  //   return await createPartnersDealContactPost( params )
  // }
  async ({
    account_number,
    body
  }: {
    account_number: any
    body: IcreatePartnersContactBody
  }) => {
    return await createPartnersDealContactPost(account_number, body)
  }
)

export const createPartnersDeal = createAsyncThunk(
  "/partners/create-deal",
  async (params: IcreatePartnersDealBody) => {
    return await createPartnersDealPost(params)
  }
)

export const getPartnerAccount = createAsyncThunk(
  "/partners/get-account",
  async (params: any) => {
    return await partnerAccountGet(params)
  }
)

export const getPartnerAccountForNewAccountForm = createAsyncThunk(
  "/partners/get-account-for-new-account-form",
  async (params: any) => {
    return await partnerAccountGet(params)
  }
)

export const getPartnerLeadForNewAccountForm = createAsyncThunk(
  "/partner/get-lead-for-new-account-form",
  async (params: any) => {
    return await partnerLeadAccountGet(params)
  }
)

export const createPartnersAccount = createAsyncThunk(
  "/partners/create-account",
  async (params: any) => {
    return await partnerAccountCreate(params)
  }
)

export const createPartnerLeadAccount = createAsyncThunk(
  "/partners/create-lead-account",
  async (params: any) => {
    return await partnerLeadAccountCreate(params)
  }
)

export const updatePartnerAccount = createAsyncThunk(
  "/partners/update-partner-account",
  async ({ accounts_id, body }: { accounts_id: any; body: any }) => {
    return await partnerAccountPut(accounts_id, body)
  }
)

export const updateLeadPartnerAccount = createAsyncThunk(
  "/partners/update-lead-partner-account",
  async ({ lead_id, body }: { lead_id: any; body: any }) => {
    return await partnerLeadAccountPut(lead_id, body)
  }
)

export const updatePartnersAccount = createAsyncThunk(
  "/partners/update-account",
  async ({
    accounts_id,
    body
  }: {
    accounts_id: number
    body: IupdatePartnersAccountBody
  }) => {
    return await updatePartnersAccountPut(accounts_id, body)
  }
)

export const updatePartnersContact = createAsyncThunk(
  "/partners/update-contact",
  async ({
    account_id,
    contacts_id,
    body
  }: {
    account_id: number
    contacts_id: number
    body: IupdatePartnersContactBody
  }) => {
    return await updatePartnersContactPut(account_id, contacts_id, body)
  }
)

export const updatePartnersAccountData = createAsyncThunk(
  "/partners/update-contact",
  async ({
    account_number,
    contact_number,
    body
  }: {
    account_number: any
    contact_number: any
    body: any
  }) => {
    return await updatePartnersAccountUpdate(
      account_number,
      contact_number,
      body
    )
  }
)

export const getPartnerContact = createAsyncThunk(
  "/partners/get-contact",
  async (finalParams: any) => {
    return await partnerContactsGet(finalParams)
  }
)

export const getPartnerSingleContactDetail = createAsyncThunk(
  "/partners/get-contact",
  async (finalParams: any) => {
    return await partnerSingleContactDetailsGet(finalParams)
  }
)

export const getPartnerContactList = createAsyncThunk(
  "/partners/get-contact",
  async (account_number: any) => {
    return await partnerContactsGetList(account_number)
  }
)

export const createPartnerContact = createAsyncThunk(
  "/partners/create-contact",
  async ({ account_id, body }: { account_id: string; body: any }) => {
    return await partnerContactCreate(account_id, body)
  }
)

export const addPartnerOnboarding = createAsyncThunk(
  "/partners/add-partner-onboarding",
  async ({
    partner_account_id,
    payload
  }: {
    partner_account_id: string
    payload: any
  }) => {
    return await partnerOnboarding(partner_account_id, payload)
  }
)

export const addPartnerLeadOnboarding = createAsyncThunk(
  "/partners/add-partner-onboarding-lead",
  async ({
    partner_lead_id,
    payload
  }: {
    partner_lead_id: string
    payload: any
  }) => {
    return await partnerLeadOnboarding(partner_lead_id, payload)
  }
)

export const createPartnerAccount = createAsyncThunk(
  "/partners/create-accounts",
  async ({ account_number, body }: { account_number: any; body: any }) => {
    return await createPartnerAccounts(account_number, body)
  }
)

export const updatePartnersDeal = createAsyncThunk(
  "/partners/update-deal",
  async ({
    deals_id,
    body
  }: {
    deals_id: number
    body: IupdatePartnersDealBody
  }) => {
    return await updatePartnersDealPut(deals_id, body)
  }
)

// for Resource Tag
export const getPartnerResource = createAsyncThunk(
  "/partners/get-resource",
  async (params: any) => {
    return await partnerResourceGet(params)
  }
)

export const createPartnersResourceTag = createAsyncThunk(
  "/partners/resource-tag",
  async (params: any) => {
    return await partnerResourceTag(params)
  }
)

// updatePartnerResource

export const updatePartnerResource = createAsyncThunk(
  "/partners/update-resource-tag",
  async (params: any) => {
    return await partnerUpdateResourceTag(params)
  }
)

export const deleteResourceTag = createAsyncThunk(
  "/partners/delete-resource-tag",
  async (params: any) => {
    return await partnerDeleteResourceTag(params)
  }
)

// get Resource option

export const getResourceOptions = createAsyncThunk(
  "/partners/get-resource-options",
  async (params: any) => {
    return await partnerResourceOptions(params)
  }
)

export const getResourceFilterOptions = createAsyncThunk(
  "/partners/get-filter-options",
  async (params: any) => {
    return await partnerResourceFilterOptions(params)
  }
)

// partner document upload

export const partnerDocumentUpload = createAsyncThunk(
  "partner/resource/document/upload",
  async (payload: any) => {
    return await uploadPartnerDocument(payload)
  }
)
// uploadNewAssets

export const uploadNewAssets = createAsyncThunk(
  "partner/assets/document",
  async (payload: any) => {
    return await PartnerAssestDocument(payload)
  }
)

export const getAssetMediaURL = createAsyncThunk(
  "/partners/resources/get-asset-media-url",
  async (asset_id: string) => {
    return await assetMediaURLGet(asset_id)
  }
)

export const deleteAsset = createAsyncThunk(
  "/partners/resources/delete",
  async (asset_id: string) => {
    return await assetDelete(asset_id)
  }
)

export const partnerDocumentUpdate = createAsyncThunk(
  "/partners/asset/update",
  async (payload: any) => {
    return await updatePartnerDocument(payload)
  }
)

export const getAssetData = createAsyncThunk(
  "partner/assets/data",
  async (payload: any) => {
    return await PartnerGetAssestData(payload)
  }
)

export const getPartnerAccountDetails = createAsyncThunk(
  "/partners/get-account-details",
  async (accounts_id: any) => {
    return await partnerAccountDetailsGet(accounts_id)
  }
)

export const getLeadAccountDetails = createAsyncThunk(
  "/partners/get-account-details",
  async (lead_id: any) => {
    return await leadAccountDetails(lead_id)
  }
)

export const deletePartnerResourceSingleTag = createAsyncThunk(
  "/partners/delete-partner-resource-single-tag",
  async (params: any) => {
    return await partnerResourceDeleteSingle(params)
  }
)

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    clearPartnerSlice: () => initialState,
    // Account
    savePartnerAccountDetailsSlice(state, action: PayloadAction<any>) {
      state.accountDetails = action.payload
    },
    resetPartnerAccountDetailsSlice(state) {
      state.accountDetails = undefined
    },

    saveDealPartnerDetailsSlice(state, action: PayloadAction<any>) {
      state.dealPartnerDetails = action.payload
    },

    // Contact
    savePartnerContactDetailsSlice(state, action: PayloadAction<any>) {
      state.contactDetails = action.payload
    },
    resetPartnerContactDetailsSlice(state) {
      state.contactDetails = undefined
    },

    // Deal
    savePartnerDealDetailsSlice(state, action: PayloadAction<any>) {
      state.dealDetails = action.payload
    },
    resetPartnerDealDetailsSlice(state) {
      state.dealDetails = undefined
    },
    setContactDetails(state, action) {
      return action.payload
    },

    // Partner Onboarding
    setPartnerOnboardingStatus(state, action) {
      state.isPartnerOnboarding = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getRegisteredDealsList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.dealPipelineData = action.payload
      }
    )

    // Account
    builder.addCase(
      getPartnerAccount.fulfilled,
      (state, action: PayloadAction<any>) => {
        // state.accountData = action.payload.data.accounts_data
        state.accountData = action.payload.data
      }
    )
    builder.addCase(
      createPartnersDealAccount.fulfilled,
      (state, action: any) => {
        state.accountDetails = {
          ...action?.meta?.arg,
          accounts_id: action.payload.data.accounts_id
        }
      }
    )
    builder.addCase(updatePartnersAccount.fulfilled, (state, action: any) => {
      state.accountDetails = {
        ...action?.meta?.arg?.body,
        accounts_id: action?.meta?.arg?.accounts_id
      }
    })

    // Partner Onboarding
    builder
      .addCase(getPartnerOnboardingData.pending, (state) => {
        state.loadingPartnerOnboarding = true
        state.error = null
      })

      .addCase(getPartnerOnboardingData.fulfilled, (state, action) => {
        state.loadingPartnerOnboarding = false
        state.partnerOnboardingData = action.payload?.data
        state.isPartnerOnboarding =
          action.payload?.data?.partner_onboarding?.partner_status ===
          "onboarding"
      })

      .addCase(getPartnerOnboardingData.rejected, (state, action) => {
        state.loadingPartnerOnboarding = false
        state.error =
          action.error?.message ?? "Failed to fetch partner onboarding Data"
      })

    builder
      .addCase(addPartnerOnboarding.pending, (state, action) => {
        state.loadingAddPartnerOnboarding = true
      })
      .addCase(addPartnerOnboarding.fulfilled, (state, action) => {
        state.loadingAddPartnerOnboarding = false
      })
      .addCase(addPartnerOnboarding.rejected, (state, action) => {
        state.loadingAddPartnerOnboarding = false
      })

    // Partner Lead Onboarding
    builder
      .addCase(getPartnerLeadOnboardingData.pending, (state) => {
        state.loadingPartnerOnboarding = true
        state.error = null
      })

      .addCase(getPartnerLeadOnboardingData.fulfilled, (state, action) => {
        state.loadingPartnerOnboarding = false
        state.partnerOnboardingData = action.payload?.data
        state.isPartnerOnboarding =
          action.payload?.data?.partner_onboarding?.partner_status ===
          "onboarding"
      })

      .addCase(getPartnerLeadOnboardingData.rejected, (state, action) => {
        state.loadingPartnerOnboarding = false
        state.error =
          action.error?.message ?? "Failed to fetch partner onboarding Data"
      })

    builder
      .addCase(addPartnerLeadOnboarding.pending, (state, action) => {
        state.loadingAddPartnerOnboarding = true
      })
      .addCase(addPartnerLeadOnboarding.fulfilled, (state, action) => {
        state.loadingAddPartnerOnboarding = false
      })
      .addCase(addPartnerLeadOnboarding.rejected, (state, action) => {
        state.loadingAddPartnerOnboarding = false
      })

    // Resource Tag

    builder
      .addCase(getPartnerResource.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getPartnerResource.fulfilled, (state, action) => {
        state.loading = false
        state.resourceData = action.payload
      })
      .addCase(getPartnerResource.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    // Contact
    builder.addCase(
      createPartnersDealContact.fulfilled,
      (state, action: any) => {
        state.contactDetails = {
          ...action?.meta?.arg,
          contacts_id: action.payload.data.contacts_id
        }
      }
    )
    builder.addCase(updatePartnersContact.fulfilled, (state, action: any) => {
      state.contactDetails = {
        ...action?.meta?.arg?.body,
        contacts_id: action?.meta?.arg?.contacts_id
      }
    })

    builder.addCase(
      getPartnerContact.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.contactsData = action.payload.data
      }
    )

    //  Resource Options
    builder
      .addCase(getResourceOptions.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getResourceOptions.fulfilled, (state, action) => {
        state.loading = false
        state.resourceOption = action.payload
      })
      .addCase(getResourceOptions.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    // Resource Card
    builder
      .addCase(getResourceFilterOptions.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getResourceFilterOptions.fulfilled, (state, action) => {
        state.loading = false
        state.resourceCardData = action.payload
      })
      .addCase(getResourceFilterOptions.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder
      .addCase(partnerDocumentUpload.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(partnerDocumentUpload.fulfilled, (state, action) => {
        state.loading = false
        state.resourceDocument = action.payload
      })
      .addCase(partnerDocumentUpload.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    // For Assets Data
    builder
      .addCase(getAssetData.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAssetData.fulfilled, (state, action) => {
        state.loading = false
        state.assetsData = action.payload
      })
      .addCase(getAssetData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export default partnerSlice.reducer
export const {
  clearPartnerSlice,
  savePartnerAccountDetailsSlice,
  saveDealPartnerDetailsSlice,
  resetPartnerAccountDetailsSlice,
  savePartnerContactDetailsSlice,
  resetPartnerContactDetailsSlice,
  savePartnerDealDetailsSlice,
  resetPartnerDealDetailsSlice,
  setContactDetails,
  setPartnerOnboardingStatus
} = partnerSlice.actions

export const selectRegisteredDealData = (state: RootState) =>
  state.partner.dealPipelineData

export const useRegisteredDealData = () => {
  const dealPipelineData: any = useAppSelector(selectRegisteredDealData)
  return useMemo(() => dealPipelineData, [dealPipelineData])
}

// Account
export const accountDeailsData = (state: RootState) =>
  state.partner.accountDetails

export const useAccountDetails = () => {
  const accountDeails: any = useAppSelector(accountDeailsData)
  return useMemo(() => accountDeails, [accountDeails])
}
export const accountsList = (state: RootState) => state.partner.accountData

export const useAccountList = () => {
  const partnerAccounts: any = useAppSelector(accountsList)
  return useMemo(() => partnerAccounts, [partnerAccounts])
}

// Contact
export const contactDetailsData = (state: RootState) =>
  state.partner.contactDetails

export const useContactDetails = () => {
  const contactDetails: any = useAppSelector(contactDetailsData)
  return useMemo(() => contactDetails, [contactDetails])
}

export const contactsData = (state: RootState) => state.partner.contactsData

export const useContactsData = () => {
  const contacts: any = useAppSelector(contactsData)
  return useMemo(() => contacts, [contacts])
}

// Deal
export const dealDetailsData = (state: RootState) => state.partner.dealDetails

export const useDealDetails = () => {
  const dealDetails: any = useAppSelector(dealDetailsData)
  return useMemo(() => dealDetails, [dealDetails])
}

// Resource
export const resourceList = (state: RootState) =>
  state.partner.resourceData?.data

export const useResourceTagList = () => {
  const partnerResource: any = useAppSelector(resourceList)
  return useMemo(() => partnerResource, [partnerResource])
}
