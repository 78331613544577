import { Skeleton } from "antd"
import React from "react"
import { SpanStyle } from "../../config/global.style"
import { AvatarStyle, AvatarWrapper, ParaStyle, UserInfo } from "./Avatar.style"

interface Avatarprops {
  icon?: React.ReactNode
  children?: string
  size?:
    | "large"
    | "small"
    | "default"
    | number
    | Partial<Record<"xxl" | "xl" | "lg" | "md" | "sm" | "xs", number>>
  shape?: "circle" | "square"
  src?: React.ReactNode
  alt?: string
  username?: string
  useremail?: string | undefined
  onClick?: () => void
  loading?: boolean | undefined
  className?: string
}

function Avatar(props: Avatarprops) {
  const {
    size,
    shape,
    icon,
    src,
    alt,
    username,
    useremail,
    children,
    onClick,
    loading = false,
    className
  } = props

  return (
    <AvatarWrapper>
      {loading ? (
        <Skeleton.Avatar size="large" shape="circle" />
      ) : (
        <div className="badge-wrapper">
          <AvatarStyle
            icon={icon}
            size={size}
            shape={shape}
            src={src}
            alt={alt}
            onClick={onClick}
            className={className}
          />
        </div>
      )}

      {children}
      {username || useremail ? (
        <UserInfo>
          {username && (
            <Skeleton
              title={false}
              paragraph={{ rows: 1, width: 160 }}
              active
              loading={loading}
              style={{ marginBottom: 5 }}
            >
              <ParaStyle className="username">{username}</ParaStyle>{" "}
            </Skeleton>
          )}
          {useremail && (
            <Skeleton
              title={false}
              paragraph={{ rows: 1, width: 160 }}
              active
              loading={loading}
            >
              <SpanStyle className="useremail">{useremail}</SpanStyle>
            </Skeleton>
          )}
        </UserInfo>
      ) : (
        ""
      )}
    </AvatarWrapper>
  )
}

export default Avatar
