/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ucaasLoggedSrcUrl } from "./ucaasService"
import { RootState } from "../../store/store"


interface InitialState {
  isLoading: boolean
}


const initialState: InitialState = {
  isLoading: false
}

export const getUcaasLoggedSrcUrl = createAsyncThunk(
  "/ucaas/get-ucaas-logged-src-url",
  async () => {
    return await ucaasLoggedSrcUrl()
  }
)

const ucaasSlice = createSlice({
  name: "ucaas",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase( getUcaasLoggedSrcUrl.pending, ( state ) => {
        state.isLoading = true
      } )
      .addCase( getUcaasLoggedSrcUrl.fulfilled, ( state ) => {
        state.isLoading = false
      } )
      .addCase( getUcaasLoggedSrcUrl.rejected, ( state ) => {
        state.isLoading = false
      } )
  }
})

export default ucaasSlice.reducer
// export const {
// } = ucaasSlice.actions

export const getUrlLoaderSelector = ( state: RootState ) =>
  state.ucaas.isLoading
