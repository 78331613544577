/* eslint-disable react/no-unstable-nested-components */
import { Heading4 } from "../../config/global.style"
import { useUserRolesList } from "../../pages/userRoles/userRolesSlice"
import { useAppSelector } from "../../store/hooks"
import { RootState } from "../../store/store"
import Avatar from "../Avatar/Avatar"
import BrandLogo from "../BrandLogo/BrandLogo"
import Button from "../Button/Button"
import DefaultUserIcon from "../Icons/DefaultUser"
import DropIcon from "../Icons/DropIcon"
import ExportDashboardIcon from "../Icons/ExportDashboardIcon"
import { MenuList } from "../Sidebar/MenuItems"
import UserProfile from "../UserProfile/UserProfile"
import {
  HeaderStyle,
  LogoStyle,
  NavBar,
  NavLeft,
  NavRight
} from "./Header.style"
import { useAuth0 } from "@auth0/auth0-react"
import type { MenuProps } from "antd"
import { Dropdown, Space } from "antd"
import { useLocation } from "react-router-dom"

const items: MenuProps["items"] = [
  {
    label: "Logout",
    key: "1"
  }
]

export interface Profile {
  current_meeting_room_id: string
  dir_anc: string
  dir_list: string
  domain: string
  email: string
  first_name: string
  group: string
  last_name: string
  pk: string
  time_format: string
  time_zone: string
  username: string
  avatar: string
}

function PageHeader() {
  const { user, isLoading } = useAuth0()
  const location = useLocation()
  const user_email = user?.email
  const pathname = location?.pathname
  const pathnames = pathname?.split("/")
  const { isPartnerOnboarding } = useAppSelector(
    (state: RootState) => state.partner
  )

  const rolesList = useUserRolesList()
  const billingRole = rolesList?.data?.roles_list?.Billing || null
  let iframeLink = import.meta.env.VITE_BILLING_BASE_URL

  if (billingRole === "Office Manager") {
    iframeLink = `${import.meta.env.VITE_BILLING_ROLE_WISE_URL}/selfcare/`
  } else if (billingRole === "Reseller") {
    iframeLink = `${
      import.meta.env.VITE_BILLING_ROLE_WISE_URL
    }/sso?j_username=${user_email}`
  } else if (billingRole === "Super User") {
    iframeLink = `${
      import.meta.env.VITE_BILLING_ROLE_WISE_URL
    }/sso?j_username=${user_email}`
  } else {
    iframeLink = `${import.meta.env.VITE_BILLING_BASE_URL}`
  }

  const handleBillingClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const form = document.createElement("form")
    form.method = "POST"
    form.action = iframeLink
    form.target = "_blank"
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "j_username"
    input.value = user_email || ""
    form.appendChild(input)
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  }

  const handleSupportClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    window.open(import.meta.env?.VITE_SUPPORT_URL, "_blank", "noopener")
  }

  const getTitle = () => {
    const user: any = MenuList.find((val: any) =>
      val?.id === "lms"
        ? val?.url.includes(`/${pathnames[1]}`)
        : val?.url === `/${pathnames[1]}` ||
          (location.pathname === "/" && val?.id === "dashboard")
    )
    if (!user && pathnames[1] === "partner") {
      return "Partner"
    }
    return user?.title
  }

  return (
    <HeaderStyle className="header">
      <LogoStyle>
        <BrandLogo logoIcon />
      </LogoStyle>
      <NavBar>
        <NavLeft>{/* <Heading4>{getTitle()}</Heading4> */}</NavLeft>
        <NavRight>
          <div>
            <Button
              size="small"
              type="primary"
              icon={<ExportDashboardIcon />}
              onClick={handleSupportClick}
            >
              Support
            </Button>
          </div>
          <div>
            {!isPartnerOnboarding && billingRole && isLoading && (
              <Button
                size="small"
                type="primary"
                icon={<ExportDashboardIcon />}
                onClick={handleBillingClick}
              >
                Billing
              </Button>
            )}
          </div>
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            dropdownRender={() => <UserProfile />}
            overlayClassName="userprofile-dropdown"
          >
            <a href="/" onClick={(e) => e.preventDefault()}>
              <Space>
                <div className="userprofile">
                  <Avatar
                    shape="square"
                    size="large"
                    username={user?.name || ""}
                    useremail={user?.email || ""}
                    loading={!user || isLoading}
                    src={user ? user?.picture : <DefaultUserIcon />}
                    icon={<DefaultUserIcon />}
                  />
                </div>
                <div className="drop-icon">
                  <DropIcon />
                </div>
              </Space>
            </a>
          </Dropdown>
        </NavRight>
      </NavBar>
    </HeaderStyle>
  )
}

export default PageHeader
