import {
  TRAINING_CREATE_LEARNING_LESSON,
  TRAINING_CREATE_LEARNING_TRACK,
  TRAINING_CREATE_TRACK_TAG,
  TRAINING_DELETE_LEARNING_LESSON,
  TRAINING_DELETE_LEARNING_TRACK,
  TRAINING_DELETE_TRACK_TAG,
  TRAINING_GET_ALL_TAGS_TYPE_ARR,
  TRAINING_GET_LEARNING_LESSON,
  TRAINING_GET_LEARNING_LESSON_MEDIA_URL,
  TRAINING_GET_LEARNING_SINGLE_TRACK,
  TRAINING_GET_LEARNING_TRACK,
  TRAINING_GET_TRACK_TAG,
  TRAINING_SINGLE_DELETE_TRACK_TAG,
  TRAINING_UPDATE_LEARNING_LESSON,
  TRAINING_UPDATE_LEARNING_LESSON_MEDIA,
  TRAINING_UPDATE_LEARNING_TRACK,
  TRAINING_UPDATE_LESSON_COMPLETION,
  TRAINING_UPDATE_TRACK_TAG
} from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"

// Create track tag
export const trainingTrackTag = ( data: any ) => {
  return apiInstance.post( TRAINING_CREATE_TRACK_TAG, data )
}

// Update track tag
export const trainingUpdateTrackTag = ( payload: any ) => {
  return apiInstance.put(
    TRAINING_UPDATE_TRACK_TAG( payload?.tag_id ),
    payload?.body
  )
}

// delete track tag
export const trainingDeleteTrackTag = ( payload: any ) => {
  return apiInstance.delete( TRAINING_DELETE_TRACK_TAG, {
    data: payload
  } )
}

// get track tag
export const trainingGetTrackTag = ( params: any ) => {
  return apiInstance.get( TRAINING_GET_TRACK_TAG, { params } )
}


// Create learning track
export const trainingCreateLearningTrack = ( payload: any ) => {
  return apiInstance.post( TRAINING_CREATE_LEARNING_TRACK, payload )
}

// get Learning track
export const trainingGetLearningTrack = ( params: any ) => {
  return apiInstance.get( TRAINING_GET_LEARNING_TRACK, { params } )
}

export const trainingDeleteLearningTrack = ( payload: any ) => {
  return apiInstance.delete( TRAINING_DELETE_LEARNING_TRACK( payload?.track_id ) )
}

// get all training type arr
export const trainingGetAllTagTypeArr = () => {
  return apiInstance.get( TRAINING_GET_ALL_TAGS_TYPE_ARR )
}

//Create video media url for lesson
export const trainingCreateLearningLesson = ( payload: any ) => {
  return apiInstance.post(
    TRAINING_CREATE_LEARNING_LESSON( payload?.track_id ),
    payload?.body
  )
}

//Create video media url for lesson
export const trainingUpdateLearningLessonMedia = ( payload: any ) => {
  return apiInstance.put(
    TRAINING_UPDATE_LEARNING_LESSON_MEDIA( payload?.track_id, payload?.lesson_id ),
    payload?.body
  )
}

//Update learning lesson using video url
export const trainingUpdateLearningLesson = ( payload: any ) => {
  return apiInstance.put(
    TRAINING_UPDATE_LEARNING_LESSON( payload?.track_id, payload?.lesson_id ),
    payload?.body
  )
}

//get learning lesson
export const trainingGetLearningLesson = ( params: any ) => {
  return apiInstance.get( TRAINING_GET_LEARNING_LESSON( params?.track_id ), {
    params
  } )
}

export const trainingDeleteLearningLesson = (payload: any) => {
  return apiInstance.delete(TRAINING_DELETE_LEARNING_LESSON(payload?.track_id, payload?.lesson_id))
}


export const trainingUpdateLearningTrack = (payload: any) => {
  const { track_id, ...restPayload } = payload
  return apiInstance.put(
    TRAINING_UPDATE_LEARNING_TRACK(track_id),
    restPayload
  )
}

export const trainingGetLearningLessonMediaUrl = (params: any) => {
  return apiInstance.get(TRAINING_GET_LEARNING_LESSON_MEDIA_URL(params?.track_id, params?.lesson_id))
}

export const trainingGetLearningSingleTrack = ( params: any ) => {
  return apiInstance.get( TRAINING_GET_LEARNING_SINGLE_TRACK( params?.track_id ) )
}

export const trainingSingleDeleteTrackTag = ( payload: any ) => {
  return apiInstance.delete( TRAINING_SINGLE_DELETE_TRACK_TAG( payload?.tag_id ) )
}

export const trainingUpdateLessonCompletion = (payload: any) => {
  const { track_id, lesson_id } = payload
  return apiInstance.put(
    TRAINING_UPDATE_LESSON_COMPLETION(track_id, lesson_id)
  )
}