import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  trainingCreateLearningLesson,
  trainingCreateLearningTrack,
  trainingDeleteLearningLesson,
  trainingDeleteLearningTrack,
  trainingDeleteTrackTag,
  trainingGetAllTagTypeArr,
  trainingGetLearningLesson,
  trainingGetLearningLessonMediaUrl,
  trainingGetLearningSingleTrack,
  trainingGetLearningTrack,
  trainingGetTrackTag,
  trainingSingleDeleteTrackTag,
  trainingTrackTag,
  trainingUpdateLearningLesson,
  trainingUpdateLearningLessonMedia,
  trainingUpdateLearningTrack,
  trainingUpdateLessonCompletion,
  trainingUpdateTrackTag
} from "./TrainingServices"
import { RootState } from "../../store/store"
import { LessonItemType } from "./OperationsEngineer"

type TrackTagType = {
  tagData: any
  isLoading: boolean
  error: any
}

interface InitialStateType {
  trackTag: TrackTagType
  learning: any
}

interface Tag {
  tag_id: number
  track_type: string
  track_tag_value: string
}

const initialState: InitialStateType = {
  trackTag: {
    tagData: {
      tracks_data: [],
      page: 1,
      page_size: 10,
      total_records: 0,
      total_pages: 0
    },
    isLoading: false,
    error: null
  },
  learning: {
    trackData: {
      tracks_data: [],
      page: 1,
      page_size: 10,
      total_records: 2,
      total_pages: 1
    },
    trackLessonData: [],
    trackTypeOptions: [],
    productOptions: [],
    audienceOptions: [],
    isTagTypeArrLoading: false,
    trackError: null,
    getTrackLoader: false,
    addTrackLoader: false,
    getTracKLessonLoader: false,
    getMediaUrlLoader:false,
    getSingleTrackLoader:false,
  }
}

export const getTrainingTrackTag = createAsyncThunk(
  "/training/get-track-tags",
  async ( params: any ) => {
    return await trainingGetTrackTag( params )
  }
)

export const createTrainingTrackTag = createAsyncThunk(
  "/training/track-tags",
  async ( params: any ) => {
    return await trainingTrackTag( params )
  }
)

export const updateTrainingTrackTag = createAsyncThunk(
  "/training/update-track-tags",
  async ( params: any ) => {
    return await trainingUpdateTrackTag( params )
  }
)

export const deleteTrainingTrackTag = createAsyncThunk(
  "/training/delete-track-tags",
  async ( params: any ) => {
    return await trainingDeleteTrackTag( params )
  }
)

export const getLearningTrack = createAsyncThunk(
  "/training/get-learning-track",
  async ( params: any ) => {
    return await trainingGetLearningTrack( params )
  }
)

export const createLearningTrack = createAsyncThunk(
  "/training/create-learning-track",
  async ( payload: any ) => {
    return await trainingCreateLearningTrack( payload )
  }
)

export const deleteLearningTrack = createAsyncThunk(
  "/training/delete-learning-track",
  async ( params: any ) => {
    return await trainingDeleteLearningTrack( params )
  }
)

export const updateLearningTrack = createAsyncThunk(
  "/training/update-learning-track",
  async (payload: any) => {
    return await trainingUpdateLearningTrack(payload)
  }
)

export const getAllTrainingTagTypeArr = createAsyncThunk(
  "/training/get-training-tag-type-arr",
  async () => {
    return await trainingGetAllTagTypeArr()
  }
)

//Create video media file upload
export const createLearningLesson = createAsyncThunk(
  "/training/create-learning-lesson",
  async ( payload: any ) => {
    return await trainingCreateLearningLesson( payload )
  }
)

export const updateLearningLessonMedia = createAsyncThunk(
  "/training/update-learning-lesson-media",
  async ( payload: any ) => {
    return await trainingUpdateLearningLessonMedia( payload )
  }
)

export const updateLearningLesson = createAsyncThunk(
  "/training/update-learning-lesson",
  async ( payload: any ) => {
    return await trainingUpdateLearningLesson( payload )
  }
)

export const updateLearningLessonCompletion = createAsyncThunk(
  "/training/update-learning-lesson-completion",
  async ( payload: any ) => {
    return await trainingUpdateLessonCompletion( payload )
  }
)

export const getLearningLesson = createAsyncThunk(
  "/training/get-learning-lesson",
  async ( params: any ) => {
    return await trainingGetLearningLesson( params )
  }
)

export const deleteLearningLesson = createAsyncThunk(
  "/training/delete-learning-lesson",
  async ( params: any ) => {
    return await trainingDeleteLearningLesson( params )
  }
)

export const getLearningLessonMediaUrl = createAsyncThunk(
  "/training/get-learning-lesson-media-url",
  async (params: any) => {
    return await trainingGetLearningLessonMediaUrl( params )
  }
)

export const getLearningSingleTrack = createAsyncThunk(
  "/training/get-learning-single-track",
  async ( params: any ) => {
    return await trainingGetLearningSingleTrack( params )
  }
)

export const deleteSingleTrainingTrackTag = createAsyncThunk(
  "/training/delete-single-track-tags",
  async ( params: any ) => {
    return await trainingSingleDeleteTrackTag( params )
  }
)

const trainingSlice = createSlice( {
  name: "training",
  initialState,
  reducers: {

    removetrackLesson: ( state, action ) => {
      state.learning.trackLessonData = state.learning.trackLessonData.filter( ( item: any ) => !( item?.id === action.payload?.lesson_id && item?.track_id === action.payload?.track_id ) )
    },
    removeLearningtrack: ( state, action ) => {
      state.learning.trackData.tracks_data = state.learning.trackData.tracks_data.filter( ( item: any ) => item?.track_id !== action.payload?.track_id )
    },
    updateLessonCompletion: (state, action) => {
      const lessonDataIndex = state.learning.trackLessonData.findIndex((item: any) => {
        return item?.id == action.payload?.lesson_id && item?.track_id == action.payload?.track_id
      })
      if (lessonDataIndex !== -1) {
        state.learning.trackLessonData[lessonDataIndex] = {
          ...state.learning.trackLessonData[lessonDataIndex],
          is_completed: true
        }
      }
    }
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( getTrainingTrackTag.pending, ( state ) => {
        state.trackTag.isLoading = true
        state.trackTag.error = null
      } )
      .addCase( getTrainingTrackTag.fulfilled, ( state, action ) => {
        state.trackTag.isLoading = false
        state.trackTag.tagData = action.payload?.data
      } )
      .addCase( getTrainingTrackTag.rejected, ( state, action ) => {
        state.trackTag.isLoading = false
        state.trackTag.error = action?.payload || ""
      } )

    builder
      .addCase( getLearningTrack.pending, ( state ) => {
        state.learning.getTrackLoader = true
        state.learning.trackError = null
      } )
      .addCase( getLearningTrack.fulfilled, ( state, action ) => {
        state.learning.getTrackLoader = false
        state.learning.trackData = action.payload?.data
      } )
      .addCase( getLearningTrack.rejected, ( state, action ) => {
        state.learning.getTrackLoader = false
        state.learning.trackError = action?.payload || ""
      } )

    builder
      .addCase( createLearningTrack.pending, ( state ) => {
        state.learning.addTrackLoader = true
        state.learning.trackError = null
      } )
      .addCase( createLearningTrack.fulfilled, ( state, action ) => {
        state.learning.addTrackLoader = false
        state.learning.trackData.tracks_data = [
          action.payload?.data,
          ...state.learning.trackData.tracks_data
        ]
      } )
      .addCase( createLearningTrack.rejected, ( state, action ) => {
        state.learning.addTrackLoader = false
        state.learning.trackError = action?.payload || ""
      } )

    builder
      .addCase( getAllTrainingTagTypeArr.pending, ( state ) => {
        state.learning.isTagTypeArrLoading = true
        state.learning.trackError = null
      } )
      .addCase( getAllTrainingTagTypeArr.fulfilled, ( state, action ) => {
        state.learning.trackTypeOptions =
          action.payload?.data?.track_type_list?.map( ( type: Tag ) => ( {
            label: type?.track_tag_value,
            value: type?.track_tag_value
          } ) )
        state.learning.productOptions = action.payload?.data?.product_list?.map(
          ( type: Tag ) => ( {
            label: type?.track_tag_value,
            value: type?.track_tag_value
          } )
        )
        state.learning.audienceOptions =
          action.payload?.data?.audience_list?.map( ( type: Tag ) => ( {
            label: type?.track_tag_value,
            value: type?.track_tag_value
          } ) )
        state.learning.isTagTypeArrLoading = false
      } )
      .addCase( getAllTrainingTagTypeArr.rejected, ( state, action ) => {
        state.learning.trackError = action?.payload || ""
        state.learning.isTagTypeArrLoading = false
      } )

    builder
      .addCase( getLearningLesson.pending, ( state ) => {
        state.learning.getTracKLessonLoader = true
      } )
      .addCase( getLearningLesson.fulfilled, ( state, action ) => {
        state.learning.getTracKLessonLoader = false
        state.learning.trackLessonData = action.payload?.data
      } )
      .addCase( getLearningLesson.rejected, ( state, action ) => {
        state.learning.getTracKLessonLoader = false
      } )

      builder
      .addCase( getLearningLessonMediaUrl.pending, ( state ) => {
        state.learning.getMediaUrlLoader = true
      } )
      .addCase( getLearningLessonMediaUrl.fulfilled, ( state ) => {
        state.learning.getMediaUrlLoader = false
      } )
      .addCase( getLearningLessonMediaUrl.rejected, ( state ) => {
        state.learning.getMediaUrlLoader = false
      } )

      builder
      .addCase( getLearningSingleTrack.pending, ( state ) => {
        state.learning.getSingleTrackLoader = true
      } )
      .addCase( getLearningSingleTrack.fulfilled, ( state ) => {
        state.learning.getSingleTrackLoader = false
      } )
      .addCase( getLearningSingleTrack.rejected, ( state ) => {
        state.learning.getSingleTrackLoader = false
      } )
  }
} )


export const {
  removetrackLesson,
  removeLearningtrack,
  updateLessonCompletion
} = trainingSlice.actions

export default trainingSlice.reducer

export const trackTagListSelector = ( state: RootState ) =>
  state.training.trackTag.tagData

export const trackTagListLoaderSelector = ( state: RootState ) =>
  state.training.trackTag.isLoading

export const learningTrackDataSelector = ( state: RootState ) =>
  state.training.learning.trackData

export const productOptionsSelector = ( state: RootState ) =>
  state.training.learning.productOptions

export const audienceOptionsSelector = ( state: RootState ) =>
  state.training.learning.audienceOptions

export const trackTypeOptionsSelector = ( state: RootState ) =>
  state.training.learning.trackTypeOptions

export const optionsLoaderSelector = ( state: RootState ) =>
  state.training.learning.isTagTypeArrLoading

export const getTrackLoaderSelector = ( state: RootState ) =>
  state.training.learning.getTrackLoader

export const AddTrackLoaderSelector = ( state: RootState ) =>
  state.training.learning.addTrackLoader

export const trackLessonListSelector = ( state: RootState ) =>
  state.training.learning.trackLessonData

export const trackLessonListLoaderSelector = ( state: RootState ) =>
  state.training.learning.getTracKLessonLoader

export const trackLessonMediaUrlLoaderSelector = ( state: RootState ) =>
  state.training.learning.getMediaUrlLoader

export const trackLessonSingleTracklLoaderSelector = ( state: RootState ) =>
  state.training.learning.getSingleTrackLoader