export const INVENTORY = "/v2/numbers"
export const INVENTORY_EXPORT = "/v1/phonenumbers/export_unassigned"
export const INVENTORY_DELETE = "/v2/numbers"
export const INVENTORY_EDIT = "/v1/numbers/update"
export const GET_REGION = "/v2/numbers/service/cx"

export const CUSTOMER = "/v2/customers"
export const CUSTOMER_EXPORT = "/v1/phonenumbers/export_unassigned"
export const CUSTOMER_FIRST_NAME_DIRECTORY =
  "/v1/onecloud/domains/firstnamedirectory"
export const CUSTOMER_DELETE = "/v1/phonenumbers/delete"
export const CUSTOMER_EDIT = (customer_id: string) =>
  `v2/customers/${customer_id}`
export const DOMAIN_VALIDATE = "v2/customers/validate"
export const EXTENSION_VALIDATE = (reseller: string, domain: string) =>
  `/v2/customers/resellers/${reseller}/domains/${domain}/users/validate`
export const CUSTOMER_SUMMARY = "/v2/customers/summary"
export const GET_ZOHO_ACCOUNT = "/v2/customers/accounts"

export const SECURE_FAX_USER = "/v1/securefax/search_users"
export const SECURE_FAX_CREATE = "/v1/securefax/create_user"
export const SECURE_FAX_UPDATE = "/v1/securefax/update_user"
export const SECURE_FAX_DELETE = "/v1/securefax/delete_user"
export const SECURE_FAX_DOMAIN = "/v1/securefax/get_domains"
export const SECURE_FAX_SEARCH_USER = "/v1/securefax/get_users"
export const ADD_USER_NUMBER = "/v1/securefax/fax_number/add_assigned_user"
export const REMOVE_USER_NUMBER =
  "/v1/securefax/fax_number/remove_assigned_user"
export const ADD_USER_EMAIL = "/v1/securefax/fax_number/add_additional_delivery"
export const REMOVE_USER_EMAIL =
  "/v1/securefax/fax_number/remove_additional_delivery"

export const SECURE_FAX_NUMBER = "/v1/securefax/search_numbers"
export const SECURE_FAX_DOMAIN_NUMBER_LIST =
  "/v1/securefax/fax_number/get_domain_numbers"
export const SECURE_FAX_NUMBER_CREATE = "/v1/securefax/create_number"
export const SECURE_FAX_NUMBER_UPDATE = "/v1/securefax/update_number"
export const SECURE_FAX_NUMBER_DELETE = "/v1/securefax/delete_number"

export const SENT_FAX_LOG = "/v1/securefax/get_fax_logs"
export const SENT_FAX_UUID = "/v1/securefax/get_uuid_logs"

export const DOMAIN = "/v1/onecloud/domains/list"
export const TIME_ZONE = "/v1/securefax/get_tz"
export const DOMAIN_SITE = "/v1/securefax/get_domain_sites"
export const LICENSE_TYPES = "/v1/securefax/get_license_types"

export const ORDERS = "/v1/blec/numbers/search-feature"
export const ORDERS_DETAILS = "/v1/blec/fetch_order_details"
export const ORDERS_NOTES = "/v1/blec/number_notes"
export const SEARCH_NUMBERS = "/v1/blec/numbers/search_numbers"
export const SEARCH_PORT_NUMBERS = "/v1/blec/porting/lnpchecker"
export const SEARCH_PARENT_INFO = "/v1/blec/porting/create_parent_port_request"
export const TRUNK_LIST = "/v1/blec/list-trunk"
export const CREATE_ORDER = "/v1/blec/numbers/create_order"
export const FETCH_RESELLER = "/v1/onecloud/resellers"
export const FETCH_CUSTOMER_RESELLER = "/v2/customers/resellers"
export const RESELLER_CUSTOMERS = (reseller: string) =>
  `/v2/customers/resellers/${reseller}/domains`
export const RESELLER_CUSTOMERS_USERS = (reseller: string, domain: string) =>
  `/v2/customers/resellers/${reseller}/domains/${domain}/users`
export const SPECIFIC_CUSTOMERS_INFO = (reseller: string, domain: string) =>
  `/v2/customers/resellers/${reseller}/domains/${domain}`
export const USER_PERMISSIONS = (
  reseller: string,
  domain: string,
  user_id: string
) =>
  `/v2/customers/resellers/${reseller}/domains/${domain}/users/${user_id}/permissions`

// Auth0 User Roles
export const AUTH0_USER_ROLES = "/v1/users/fetch_roles"

export const UPLOAD_PORT_DOCUMENT = "/v1/blec/loa-files"
export const VLIDATE_PORT_ADDRESS = "/v1/blec/porting/validate_address"
export const UPDATE_PORTIN_ORDER = "/v1/blec/update_port_in_order"
export const GET_LOA_FILES = "/v1/blec/loa-files"
export const RETRIVE_LOA_FILES = "/v1/blec/retrieve_loa_files"
export const DELETE_LOA_FILES = "/v1/blec/loa-files"
export const PORT_ORDER_NOTES = "/v1/blec/port-order-note"
export const PORTIN_DRAFT_TO_SUBMIT = "/v1/blec/port_in_draft_to_submit"
export const PORTIN_CANCEL = "/v1/blec/cancel-order"

export const GET_CARRIERS = "/v1/blec/carriers"

export const GET_ALL_DEALS = "/v2/partners/deals"
export const GET_DEALS_DATA = "/v2/partners/deals"
export const UPDATE_DEALS_DATA = (id: string) => `/v2/partners/deals/${id}`
export const GET_DEALS_TIMELINE_DATA = (id: string) =>
  `v2/partners/deals/${id}/timeline`

export const CREATE_PARTNERS_DEAL_ACCOUNT = "/v2/partners/accounts"
export const GET_PARTNER_ACCOUNTS = "/v2/partners/accounts"
export const GET_PARTNER_LEAD_ACCOUNTS = "/v2/partners/leads"
export const PARTNER_ACCOUNT = "/v2/partners/accounts"
export const PARTNER_LEAD_ACCOUNT = "/v2/partners/leads"
export const GET_PARTNER_ACCOUNT_DETAILS = (accounts_id: string) =>
  `/v2/partners/accounts/${accounts_id}`
export const GET_LEAD_ACCOUNT_DETAILS = (lead_id: string) =>
  `/v2/partners/leads/${lead_id}`

export const UPDATE_PARTNERS_DEAL_ACCOUNT = (accounts_id: number) =>
  `/v2/partners/accounts/${accounts_id}`
export const UPDATE_PARTNER_ACCOUNT = (accounts_id: any) =>
  `/v2/partners/accounts/${accounts_id}`
export const UPDATE_PARTNER_LEAD_ACCOUNT = (lead_id: string) =>
  `/v2/partners/leads/${lead_id}`

// export const CREATE_PARTNERS_DEAL_CONTACT = "/v2/partners/deal/contacts"
export const CREATE_PARTNERS_DEAL_CONTACT = (account_number: any) =>
  `/v2/partners/accounts/${account_number}/contacts`

export const CREATE_PARTNERS_DEAL = "/v2/partners/deals"

// export const UPDATE_PARTNERS_CONTACT = ( account_id: number, contacts_id: number ) => `/v2/partners/accounts/${account_id}/contacts/${contacts_id}`
export const UPDATE_PARTNERS_CONTACT = (
  account_number: number,
  contact_number: number
) => `/v2/partners/accounts/${account_number}/contacts/${contact_number}`

export const UPDATE_PARTNERS_DEAL = (deals_id: number) =>
  `/v2/partners/deals/${deals_id}`

export const GET_ALL_PARTNER_CONTACTS = (account_number: string) =>
  `/v2/partners/accounts/${account_number}/contacts`

export const GET_SINGLE_PARTNER_CONTACTS_DETAILS = (
  account_number: string,
  contact_number: number
) => `/v2/partners/accounts/${account_number}/contacts/${contact_number}`

// export const CREATE_PARTNER_CONTACT = "/v2/partners/contacts"
export const CREATE_PARTNER_CONTACT = (account_number: any) =>
  `/v2/partners/accounts/${account_number}/contacts`

// export const UPDATE_PARTNER_CONTACT = (contacts_id: string) => `/v2/partners/contacts/${contacts_id}`
export const UPDATE_PARTNERS_ACCOUNT_CONTACTS = (
  account_number: number,
  contact_number: number
) => `/v2/partners/accounts/${account_number}/contacts/${contact_number}`

export const GET_PARTNERS_DETAILS = "/v2/partners/partner"
export const PARTNER_RESOURCE_TAG = "/v2/partners/resources/asset/tags"
export const PARTNER_CREATE_RESOURCE_TAG = "/v2/partners/resources/tags"
export const PARTNER_UPDATE_RESOURCE_TAG = (tag_id: any) =>
  `/v2/partners/resources/tags/${tag_id}`
export const PARTNER_DELETE_RESOURCE_TAG = "/v2/partners/resources/tags"
export const PARTNER_GET_RESOURCE_TAG = "/v2/partners/resources/tags"
export const PARTNER_GET_RESOURCE_TAG_FILTER = "/v2/partners/resources/assets"
export const PARTNER_GET_ASSETS = (asset_id: any) =>
  `v2/partners/resources/asset/${asset_id}`
export const PARTNER_UPLOAD_ASSETS = (asset_id: any) =>
  `v2/partners/resources/assets/${asset_id}`
export const PARTNER_UPDATE_ASSET_DOCUMENT = (asset_id: any) =>
  `/v2/partners/resources/asset/${asset_id}/media`
export const GET_ASSET_MEDIA_URL = (asset_id: string) =>
  `/v2/partners/resources/asset/${asset_id}/media`
export const DELETE_ASSET = (asset_id: string) =>
  `/v2/partners/resources/asset/${asset_id}`
export const PARTNER_RESOURCE_SINGLE_DELETE = (tag_id: string | number) =>
  `/v2/partners/resources/tags/${tag_id}`

// LMS Module Tag management api path
export const TRAINING_CREATE_TRACK_TAG = "/v2/lms/tags"
export const TRAINING_UPDATE_TRACK_TAG = (tag_id: any) =>
  `/v2/lms/tags/${tag_id}`
export const TRAINING_DELETE_TRACK_TAG = "/v2/lms/tags"
export const TRAINING_GET_TRACK_TAG = "/v2/lms/tags"

export const TRAINING_GET_LEARNING_TRACK = "/v2/lms/track"
export const TRAINING_CREATE_LEARNING_TRACK = "/v2/lms/track"
export const TRAINING_DELETE_LEARNING_TRACK = (track_id: string | number) =>
  `/v2/lms/track/${track_id}`
export const TRAINING_UPDATE_LEARNING_TRACK = (track_id: string | number) =>
  `/v2/lms/track/${track_id}`
export const TRAINING_GET_ALL_TAGS_TYPE_ARR = `/v2/lms/track/lesson/tags`
export const TRAINING_CREATE_LEARNING_LESSON = (track_id: string | number) =>
  `/v2/lms/track/${track_id}/lesson`
export const TRAINING_UPDATE_LEARNING_LESSON_MEDIA = (
  track_id: string | number,
  lesson_id: string | number
) => `/v2/lms/track/${track_id}/lesson/${lesson_id}/media`
export const TRAINING_UPDATE_LEARNING_LESSON = (
  track_id: string | number,
  lesson_id: string | number
) => `/v2/lms/track/${track_id}/lesson/${lesson_id}`
export const TRAINING_GET_LEARNING_LESSON = (track_id: string | number) =>
  `/v2/lms/track/${track_id}/lesson`
export const TRAINING_DELETE_LEARNING_LESSON = (
  track_id: string | number,
  lesson_id: string | number
) => `/v2/lms/track/${track_id}/lesson/${lesson_id}`
export const TRAINING_GET_LEARNING_LESSON_MEDIA_URL = (
  track_id: string | number,
  lesson_id: string | number
) => `/v2/lms/track/${track_id}/lesson/${lesson_id}/media`
export const TRAINING_GET_LEARNING_SINGLE_TRACK = (track_id: string | number) =>
  `/v2/lms/track/${track_id}`
export const TRAINING_SINGLE_DELETE_TRACK_TAG = (tag_id: string | number) =>
  `/v2/lms/tags/${tag_id}`
export const TRAINING_UPDATE_LESSON_COMPLETION = (
  track_id: string | number,
  lesson_id: string | number
) => `/v2/lms/track/${track_id}/lesson/${lesson_id}/completed`

export const UCAAS_SRC_URL_LOGGED_IN = "/v2/ucaas/sso"

export const CREATE_CUSTOMER_RESELLER_UCAAS_URL = (
  reseller: string,
  domain: string
) => `/v2/customers/resellers/${reseller}/domains/${domain}/sites`
export const GET_CUSTOMER_RESELLER_UCAAS_ADDRESS__LIST_URL = (
  reseller: string,
  domain: string
) => `/v2/customers/resellers/${reseller}/domains/${domain}/addresses`
export const GET_CUSTOMER_RESELLER_UCAAS_SINGLE_ADDRESS_URL = (
  reseller: string,
  domain: string,
  emergency_address_id: string
) =>
  `/v2/customers/resellers/${reseller}/domains/${domain}/addresses/${emergency_address_id}`
export const VALIDATE_CUSTOMER_RESELLER_UCAAS_ADDRESS_DETAILS_URL = (
  reseller: string,
  domain: string
) => `/v2/customers/resellers/${reseller}/domains/${domain}/addresses/validate`

// Partner onboarding constants
export const GET_PARTNER_ONBOARDING_DETAILS = (account_id: string) =>
  `/v2/partners/accounts/${account_id}/onboarding`
export const GET_PARTNER_EMBEDED_SIGNING_URL = (account_id: string) =>
  `/v2/partners/accounts/${account_id}/onboarding/sign`
export const POST_PARTNER_ONBOARDING_DATA = (account_id: string) =>
  `/v2/partners/accounts/${account_id}/onboarding`
export const GET_PARTNER_ONBOARDING_DOCUMENT_STATUS = (account_id: string) =>
  `/v2/partners/accounts/${account_id}/onboarding/document-status`

//Partner onboarding lead endpoints
export const GET_PARTNER_LEAD_ONBOARDING_DETAILS = (lead_id: string) =>
  `/v2/partners/leads/${lead_id}/onboarding`
export const GET_PARTNER_LEAD_EMBEDED_SIGNING_URL = (lead_id: string) =>
  `/v2/partners/leads/${lead_id}/onboarding/sign`
export const POST_PARTNER_LEAD_ONBOARDING_DATA = (lead_id: string) =>
  `/v2/partners/leads/${lead_id}/onboarding`
export const GET_PARTNER_LEAD_ONBOARDING_DOCUMENT_STATUS = (lead_id: string) =>
  `/v2/partners/leads/${lead_id}/onboarding/document-status`

export const GET_SUPPORTED_DEVICES = "/v2/onecloud/supported-devices"
export const GET_PARTNER_ACCOUNT_ONBOARDING_DETAILS = (account_id: string) =>
  `/v2/partners/accounts/${account_id}/onboarding`
export const VALIDATE_PARTNER_IDENTIFIER = "/v2/partners/onboarding/validate"
