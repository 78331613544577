import styled from "styled-components"
import { rgba } from "../../config/variable"

export const UserProfileStyle = styled.div`
  padding-right: 24px;
`
export const Wrapper = styled.div`
  width: 220px;
  background-color: ${(props) => props.theme.bgcolordwhiteblack};
  padding: 12px;
  box-shadow: 0px 25px 40px -10px ${(props) => rgba(props.theme.colortileblack, 0.08)};
  border-radius: 8px;
`
export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  border-bottom: 1px solid ${(props) => props.theme.multipleselectcolor};
  margin-bottom: 12px;
  .badge-wrapper {
    position: relative;
    .presence-room {
      position: absolute;
      top: 27px;
      right: -3px;
      .anticon {
        font-size: 19px !important;
        background: ${(props) => props.theme.colordwhitedark};
        color: ${(props) => props.theme.colordwhitedark};
        border-radius: 100%;
      }
    }
  }
  .statusmsg {
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.bglightblue};
    border: 1px solid ${(props) => props.theme.bgborderlight};
    box-shadow: inset 1px 1px 0px
      ${(props) => rgba(props.theme.bgcolordwhite, 0.36)};
    border-radius: 8px;
    padding: 11px;
    justify-content: space-between;
    margin-top: 12px;
    gap: 10px;
    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: ${(props) => props.theme.colorlightblack};
      word-break: break-all;
    }
    .ctaicons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`
export const AvatarProfile = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  .ant-avatar-lg {
    &.ant-avatar-icon {
      border-radius: 100%;
    }
  }
`
export const UserDetails = styled.div`
  display: block;
  .heading6 {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
`

export const MailIds = styled.div`
  display: flex;

  span {
    font-size: 9px;
    line-height: 13px;
    font-weight: 400;
    color: ${(props) => props.theme.textcolordarkgrey};
  }
`

export const SetStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btnsm {
    font-size: 10px;
    line-height: 13px;
    position: relative;
    &::before {
      content: "";
      width: 1px;
      height: 12px;
      background-color: ${(props) => props.theme.multipleselectcolor};
      position: absolute;
      left: -7px;
    }
  }
  .status {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-right: 5px;

    height: 13px;
    margin-right: 7px;
    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: ${(props) => props.theme.textcolordarkgrey};
    }
  }
  .ant-space {
    display: block;
  }
`

export const MeetingDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    white-space: nowrap;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    color: ${(props) => props.theme.colorlightblack};
  }
  .copyIcon {
    span {
      font-size: 14px;
      display: flex;
      color: ${(props) => props.theme.primary};
      margin: 0 10px 0 4px;
      cursor: pointer;
    }
  }
  .ant-select {
    width: 152px;
  }
  .ant-select-sm {
    .ant-select-selector {
      border-radius: 8px;
    }
  }
`
export const MeetingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${(props) => props.theme.multipleselectcolor};
  margin-bottom: 12px;
`

export const AccountAction = styled.div`
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  .ctalinks {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.colorlightblack};
    &:hover {
      color: ${(props) => props.theme.textcolordarkgrey};
    }
    .anticon {
      color: ${(props) => props.theme.colorbluegrey};
    }
  }
  .linkdanger {
    color: ${(props) => props.theme.colordanger};
    &:hover {
      color: ${(props) => props.theme.colordangerhover};
      .anticon {
        color: ${(props) => props.theme.colordangerhover};
      }
    }
    .anticon {
      color: ${(props) => props.theme.colordanger};
    }
  }
`
export const MsgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${(props) => props.theme.bgcolordwhiteblack};
  width: 220px;
  padding: 12px;
  box-shadow: 0px 25px 40px -10px ${(props) => rgba(props.theme.colortileblack, 0.08)};
  textarea {
    background-color: ${(props) => props.theme.bgcolordwhiteblack};
  }
  .backbtn {
    display: flex;
    justify-content: flex-start;
  }
  .donebtn {
    display: flex;
    justify-content: flex-end;
  }
  .ant-form-item {
    margin: 0;
  }
`
