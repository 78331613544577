import { Content } from "antd/es/layout/layout"
import styled from "styled-components"
import bannerTop from "../../images/banner-top-icon.svg"
import bannerCenter from "../../images/banner-center-icon.svg"

const PageContent = styled(Content)`
  height: calc(100vh - 84px);
  background: ${(props) => props.theme.widgetbgcolorskyblue};
  overflow: auto;
  position: relative;
  .ant-spinner {
    position: relative;
    display: flex;
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  h1 {
    font-size: 36px;
  }
`
export default PageContent

export const OnboardingDetailsStyles = styled.div`
  min-height: calc(100vh - 150px);
  background: white;
  border-radius: 16px;
  padding: 16px;
  position: relative;

  .tab-content-panel {
    .head {
      /* display: grid; */
      /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
      align-items: center;
      padding: 15px;
      margin-bottom: 15px;
      border-bottom: lightgrey 1px solid;
      border-radius: 8px;
      box-shadow: 0px 2px 6px 0px rgba(28, 39, 49, 0.08);

      .ant-step-comp {
        padding: 0px 0px 0px 0px;
        /* border-bottom: lightgrey 1px solid; */
      }

      .ant-steps-item-tail {
        margin-inline-start: 60px !important;
        padding: 6px 0px !important;
      }

      .ant-steps .ant-steps-item-tail::after {
        height: 2px !important;
        background-color: #d5d6dc !important;
      }

      .ant-steps-item-content {
        width: 150px !important;
      }

      .ant-steps
        .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: #38a6de !important;
      }

      .ant-steps-item-title {
        color: #757575 !important;
        font-size: 14px !important;
        font-weight: 700 !important;
      }

      .ant-steps
        .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        /* color: #000000 !important; */
        color: #757575 !important;
      }

      .ant-steps
        .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: #000000 !important;
      }

      .ant-steps-item-icon {
        margin-inline-start: 56px !important;
      }

      .button-setp {
        display: flex;
        justify-content: end;
        gap: 15px;
      }
    }
  }
`

export const TableHeaderStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
`

export const BannerStyles = styled.div`
  .banner-section {
    position: relative;
    background-color: #a8e4ff99;
    background-repeat: no-repeat;
    background-position: center;
    margin: 10px 0 10px;
    border-radius: 10px;
  }
  .banner-section .container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 25px;
    position: relative;
    z-index: 9;
    height: calc(100vh - 104px);
  }
  .banner-section::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 213px;
    height: 199px;
    background-image: url(${bannerTop});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
  }

  .banner-section::before {
    position: absolute;
    content: "";
    top: 0px;
    right: 27%;
    bottom: 0;
    width: 350px;
    height: 100%;
    background-image: url(${bannerCenter});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top;
    transform: translateX(-50%);
  }
  .image-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }

  .image-content-wrapper .content-block {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 20px;
  }
  .image-content-wrapper .content-block .content-inner {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  .image-content-wrapper .image-block {
    max-width: 50%;
    display: flex;
    align-items: flex-end;
    height: 329px;
  }
  .image-content-wrapper.image-block img {
    width: 100%;
    height: 229px;
  }
`

export const DashboardStyles = styled.div`
  padding: 0px 24px 0px;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .title-main {
      margin: 0 auto;
      padding: 0 25px;
      position: relative;
      z-index: 9;
      font-size: 26px;
      font-weight: 700;
    }

    .title-description {
      font-size: 14px;
      font-weight: 400;
      color: #8083a3;
    }

    .ant-select-selector {
      width: 124px;
      height: 38px;
      background-color: #38a6de;
      color: #fff;
    }

    .custom-select {
      position: relative;

      .ant-select-selector .ant-select-suffix {
        position: absolute;
        left: 10px; /* Adjust the left position as needed */
        right: auto; /* Reset the right position */
      }
    }

    .ant-select-selection-item {
      color: #fff;
    }
  }

  .links-app {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    .app-box {
      background-color: #ebebeb;
    }

    .links {
      min-height: 230px;
      flex: 1;
      background: ${(props) => props.theme.colordwhitedark};
      border-radius: 10px;
      color: ${(props) => props.theme.textcolordblack};

      .links-title {
        line-height: 24px;
        margin-top: 20px;
        margin-left: 24px;
        font-weight: 700;
        color: ${(props) => props.theme.colorlightblack};
      }

      .links-data {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        gap: 32px; /* Increased gap between rows */
        margin: 24px; /* Adjusted margin for consistency */
      }

      .link {
        height: 55px;
        display: flex;
        gap: 12px;
        color: #474747;
        cursor: pointer;

        .text {
          max-width: 191px;
          color: ${(props) => props.theme.textcolordblack};
          .text-title {
            margin-bottom: 6px;
            line-height: 21px;
            font-size: 14px;
            font-weight: 700;
          }

          .text-description {
            line-height: 14px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }

    .app {
      min-height: 230px;
      flex: 1;
      background: ${(props) => props.theme.colordwhitedark};
      border-radius: 10px;
      max-width: 302px;

      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }

      .app-title {
        line-height: 24px;
        margin-top: 20px;
        margin-left: 24px;
        font-weight: 700;
        color: ${(props) => props.theme.colorlightblack};
      }

      .app-data {
        display: flex;
        flex-direction: column;
        margin: 24px;
        gap: 16px; /* Adjust the gap as needed */
        justify-content: flex-start;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
          gap: 48px;
        }

        .api-doc {
          margin-top: 12px;

          @media screen and (max-width: 1200px) {
            margin-top: 0;
          }
        }

        .app-left,
        .app-right {
          display: flex;
          align-items: flex-start; /* Align items to the start of the flex container */
          gap: 12px;
          cursor: pointer;
          color: #474747;
          width: 100%;
          max-width: 210px;

          .app-left-text,
          .app-right-text {
            flex: 1;

            .app-left-text-title,
            .app-right-text-title {
              margin-bottom: 6px;
              line-height: 21px;
              font-size: 14px;
              font-weight: 700;
              width: 200px;
              color: ${(props) => props.theme.textcolordblack};
            }

            .app-left-text-description,
            .app-right-text-description {
              line-height: 14px;
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 12px; /* Ensure consistent spacing after description */
              color: ${(props) => props.theme.textcolordblack};
            }
          }
        }
      }
    }
  }

  .pipeline-revenue {
    display: flex;
    gap: 24px;
    margin-top: 24px;
  }

  .pipeline {
    min-height: 500px;
    // max-width: 790px;
    background: ${(props) => props.theme.colordwhitedark};
    border-radius: 10px;

    .tbl-cursor-pointer {
      tr.ant-table-row {
        cursor: pointer;
      }
    }

    .pipeline-header {
      margin-inline: 24px;
      margin-block: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;

      .register-btn {
        width: 147px;
      }

      .pipeline-title {
        line-height: 24px;
        font-weight: 700;
        font-size: 16px;
      }
    }

    .pipeline-table {
      margin-inline: 24px;
      // max-width: 760px;

      .status {
        border-radius: 24px;
        color: #ffffff;
        padding: 4px 8px;
        text-transform: capitalize;
      }
    }
  }

  .revenue {
    min-height: 426px;
    // min-width: 562px;
    background-color: #fff;
    border-radius: 10px;

    .revenue-header {
      margin-inline: 24px;
      margin-block: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;

      .revenue-title {
        line-height: 24px;
        font-weight: 700;
        font-size: 16px;
      }
    }

    .revenue-chart {
      margin-inline: 24px;
    }
  }
`
