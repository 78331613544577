/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
// PROJECT IMPORTS
import { AUTH0_USER_ROLES } from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"

/* ============================== SECUREFAX ADMIN SERVICES ============================== */

export const userRolesGet = () => {
  return apiInstance.get(AUTH0_USER_ROLES)
}
