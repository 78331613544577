import { useEffect } from "react";

const Support = () => {

  useEffect(() => {
    // Generate a nonce dynamically on the server-side and replace 'your_nonce_value_here'
    const nonceValue = 'https://support.onecloud.com/';

    // Create a script element for the ASAP Add-On with nonce
    const asapScript = document.createElement('script');
    asapScript.type = 'text/javascript';
    asapScript.src = `https://desk.zoho.com/portal/api/web/inapp/807134000006292001?orgId=796998932`;
    asapScript.defer = true;
    asapScript.setAttribute('nonce', nonceValue);

    // Append the script to the head of the document
    document.head.appendChild(asapScript);

    // Create a script element for the status.onecloud.com script
    const oneCloudScript = document.createElement('script');
    oneCloudScript.src = 'https://status.onecloud.com/embed/script.js';

    // Append the script to the head of the document
    document.head.appendChild(oneCloudScript);

    // Cleanup: Remove the dynamically added scripts on component unmount
    return () => {
      document.head.removeChild(asapScript);
      document.head.removeChild(oneCloudScript);
    };
  }, []);

  return (
    <>
      {/* <iframe
        src="https://support.onecloud.com/"
        style={{ width: "100%", height: "calc(100vh - 89px)", border: "none" }}
      /> */}
    </>
  )
}

export default Support
