/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
// eslint-disable-next-line import/no-cycle
import { RootState } from "../../store/store"
import { useAppSelector } from "../../store/hooks"
import {
  createOrder,
  orderListGet,
  orderDetailsGet,
  ordernotesGet,
  resellerList,
  searchNumberList,
  searchNumberPortList,
  searchPortDetails,
  searchParentInfo,
  trunkList,
  uploadPortDocument,
  validatePortAddress,
  updatePortInOrder,
  getLOAFiles,
  retriveLOAFiles,
  deleteLOAFiles,
  getOrderNotes,
  portInDraftToSubmit,
  postPortInOrderNotes,
  cancelPortInRequest
} from "./ordersService"

// eslint-disable-next-line @typescript-eslint/naming-convention
interface portDetailsBody {
  billing_phone_number: string
  business_name: string
  loa_authorizing_person: string
  account_number: string
  pin_number: string
  address_1: string
  address_2: string
  city: string
  state_code: string
  zip: string
  document_type: string
  document_name: string
  date: string
  time: string
}

const portdetailsInitialValues = {
  BillingTelephoneNumber: "",
  BusinessName: "",
  LoaAuthorizingPerson: "",
  AccountNumber: "",
  PinNumber: "",
  AddressLine1: "",
  AddressLine2: "",
  City: "",
  StateCode: "",
  Zip: "",
  documentType: "",
  documentName: "",
  date: "",
  time: ""
}

interface InitialState {
  ordersData: any
  ordersDetails: any
  portordersDetails: any
  portAddressValidateinfo: any
  portDocumentUploadDetails: any
  ordersnotes: any
  numberList: any
  numberPortList: any
  numberParentInfo: any
  trunkOptionList: any
  resellerOptionList: any
  searchNumbers: any
  selectedNumbers: any
  placeOrderDetails: any
  generalInformation: any
  checkPortabilityNumber: any
  portNumberDetails: portDetailsBody
}

/* ============================== INVANTORY SLICE ============================== */

const initialState: InitialState = {
  ordersData: {},
  ordersDetails: {},
  portordersDetails: {},
  portAddressValidateinfo: {},
  portDocumentUploadDetails: {},
  ordersnotes: {},
  numberList: [],
  numberPortList: [],
  numberParentInfo: [],
  trunkOptionList: [],
  resellerOptionList: [],
  searchNumbers: {},
  selectedNumbers: [],
  placeOrderDetails: {},
  generalInformation: {},
  checkPortabilityNumber: {},
  portNumberDetails: portdetailsInitialValues
}

export const getOrdersList = createAsyncThunk(
  "orders-list/list",
  async ( params: any ) => {
    return await orderListGet( params )
  }
)

export const getOrderdetails = createAsyncThunk(
  "orders-list/details",
  async ( params: any ) => {
    return await orderDetailsGet( params )
  }
)

export const getOrdernotes = createAsyncThunk(
  "orders-list/order-notes",
  async ( params: any ) => {
    return await ordernotesGet( params )
  }
)

export const getSearchedNumber = createAsyncThunk(
  "oeder-number/search",
  async ( payload: any ) => {
    return searchNumberList( payload )
  }
)

export const getSearchedPortNumber = createAsyncThunk(
  "oeder-number/searchport",
  async ( payload: any ) => {
    return searchNumberPortList( payload )
  }
)

export const getPortOrderDetails = createAsyncThunk(
  "oeder-number/searchportdetails",
  async ( payload: any ) => {
    return searchPortDetails( payload )
  }
)

export const getParentInformation = createAsyncThunk(
  "oeder-number/parentinfo",
  async ( payload: any ) => {
    return searchParentInfo( payload )
  }
)

export const orderCreate = createAsyncThunk(
  "oeder-create/orders",
  async ( payload: any ) => {
    return createOrder( payload )
  }
)

//  Shrikant - Document upload - start
export const portDocumentUpload = createAsyncThunk(
  "port/document/upload",
  async ( payload: any ) => {
    return await uploadPortDocument( payload )
  }
)
//  Shrikant - Document upload - end

//  Shrikant - Validate address - start
export const portAddressValidate = createAsyncThunk(
  "port/address/validate",
  async ( payload: any ) => {
    return await validatePortAddress( payload )
  }
)
//  Shrikant - Validate address - end

//  Shrikant - Update port in order - start
export const portInOrderUpdate = createAsyncThunk(
  "port/in/order/update",
  async ( payload: any ) => {
    return await updatePortInOrder( payload )
  }
)

export const getListLOAFiles = createAsyncThunk(
  "port/in/list/loa/files",
  async ( params: any ) => {
    return await getLOAFiles( params )
  }
)

export const retriveListLOAFiles = createAsyncThunk(
  "port/in/retrive/loa/files",
  async ( params: any ) => {
    return await retriveLOAFiles( params )
  }
)

export const removeListLOAFiles = createAsyncThunk(
  "port/in/remove/loa/files",
  async ( params: any ) => {
    return await deleteLOAFiles( params )
  }
)

export const listOrderNotes = createAsyncThunk(
  "port/in/order/notes",
  async ( params: any ) => {
    return await getOrderNotes( params )
  }
)

export const draftToSubmitPortIn = createAsyncThunk(
  "port/in/draft-to-submit",
  async ( params: any ) => {
    return await portInDraftToSubmit( params )
  }
)

export const createPortInOrderNotes = createAsyncThunk(
  "port-in/order/notes/create",
  async ( payload: any ) => {
    return await postPortInOrderNotes( payload )
  }
)

export const portInRequestCancel = createAsyncThunk(
  "port-in/request/cancel",
  async ( payload: any ) => {
    return await cancelPortInRequest( payload )
  }
)
//  Shrikant - Update port in order - end

export const getTrunkList = createAsyncThunk( "trunk/list", async () => {
  return trunkList()
} )

export const getResellerList = createAsyncThunk( "reseller/list", async () => {
  return resellerList()
} )

const ordersSlice = createSlice( {
  name: "orders",
  initialState,
  reducers: {
    clearOrdersSlice: () => initialState,
    setSearchNumbers(state, action) {
      state.searchNumbers = action.payload
    },
    resetNewNumber(state) {
      state.searchNumbers = {}
      state.selectedNumbers = []
      state.placeOrderDetails = {}
      state.generalInformation = {}
      state.checkPortabilityNumber = {}
      state.portNumberDetails = portdetailsInitialValues
    },
    setNumbers(state, action) {
      state.selectedNumbers = action.payload
    },
    setPlaceOrder(state, action) {
      state.placeOrderDetails = action.payload
    },
    setGeneralInformation(state, action) {
      state.generalInformation = action.payload
    },
    setCheckPortabilityNumber(state, action) {
      state.checkPortabilityNumber = action.payload
    },
    setPortNumberDetails(state, action) {
      const { key, value } = action.payload
      state.portNumberDetails[key] = value
    }
  },
  extraReducers: ( builder ) => {
    builder.addCase(
      getOrdersList.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.ordersData = action.payload?.data ? action.payload : []
      }
    )

    builder.addCase(getOrdersList.rejected, (state) => {
      state.ordersData = []
    })

    builder.addCase(
      getOrderdetails.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.ordersDetails = action.payload?.data ? action.payload : []
      }
    )

    builder.addCase(
      getPortOrderDetails.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.portordersDetails = action.payload?.data ? action.payload : []
      }
    )

    builder.addCase(
      portDocumentUpload.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.portDocumentUploadDetails = action.payload?.data ? action.payload : []
      }
    )

    builder.addCase(
      portAddressValidate.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.portAddressValidateinfo = action.payload?.data ? action.payload : []
      }
    )

    builder.addCase(
      getOrdernotes.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.ordersnotes = action.payload?.data ? action.payload : []
      }
    )


    builder.addCase(
      getSearchedNumber.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.numberList = action.payload
      }
    )

    builder.addCase(
      getSearchedPortNumber.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.numberPortList = action.payload
      }
    )

    builder.addCase(
      getParentInformation.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        state.numberParentInfo = action.payload
      }
    )

    builder.addCase(
      getTrunkList.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        const newData = action.payload?.data || []
        state.trunkOptionList = newData.map( ( val: any ) => ( {
          value: val?.AccountId,
          label: val?.Description
        } ) )
      }
    )

    builder.addCase(
      getResellerList.fulfilled,
      ( state, action: PayloadAction<any> ) => {
        const newData = action.payload?.data || []
        state.resellerOptionList = newData.map( ( val: any ) => ( {
          value: val?.name,
          label: val?.name
        } ) )
      }
    )
  }
} )

export default ordersSlice.reducer
export const {
  clearOrdersSlice,
  setSearchNumbers,
  resetNewNumber,
  setNumbers,
  setPlaceOrder,
  setGeneralInformation,
  setCheckPortabilityNumber,
  setPortNumberDetails
} = ordersSlice.actions

export const selectOrdersData = ( state: RootState ) => state.orders.ordersData
export const useOrdersData = () => {
  const ordersData = useAppSelector( selectOrdersData )
  return useMemo( () => ordersData, [ ordersData ] )
}

export const selectOrdersDetails = ( state: RootState ) => state.orders.ordersDetails
export const useOrdersDetails = () => {
  const ordersDetails = useAppSelector( selectOrdersDetails )
  return useMemo( () => ordersDetails, [ ordersDetails ] )
}

export const selectPortOrdersDetails = ( state: RootState ) => state.orders.portordersDetails
export const usePortOrdersDetails = () => {
  const portordersDetails = useAppSelector( selectPortOrdersDetails )
  return useMemo( () => portordersDetails, [ portordersDetails ] )
}

export const selectPortAddressValidateinfo = ( state: RootState ) => state.orders.portAddressValidateinfo
export const usePortAddressValidateinfo = () => {
  const portAddressValidateinfo = useAppSelector( selectPortAddressValidateinfo )
  return useMemo( () => portAddressValidateinfo, [ portAddressValidateinfo ] )
}

export const selectPortDocumentUploadDetails = ( state: RootState ) => state.orders.portDocumentUploadDetails
export const usePortDocumentUploadDetails = () => {
  const portDocumentUploadDetails = useAppSelector( selectPortDocumentUploadDetails )
  return useMemo( () => portDocumentUploadDetails, [ portDocumentUploadDetails ] )
}


export const selectOrdersnotes = ( state: RootState ) => state.orders.ordersnotes
export const useOrdersnotes = () => {
  const ordersnotes = useAppSelector( selectOrdersnotes )
  return useMemo( () => ordersnotes, [ ordersnotes ] )
}
export const selectNumberList = ( state: RootState ) => state.orders.numberList
export const useNumberList = () => {
  const numberList = useAppSelector( selectNumberList )
  return useMemo( () => numberList, [ numberList ] )
}

export const selectNumberPortList = ( state: RootState ) => state.orders.numberPortList
export const useNumberPortList = () => {
  const numberPortList = useAppSelector( selectNumberPortList )
  return useMemo( () => numberPortList, [ numberPortList ] )
}

export const selectParentInfo = ( state: RootState ) => state.orders.numberParentInfo
export const useParentInfo = () => {
  const numberParentInfo = useAppSelector( selectNumberPortList )
  return useMemo( () => numberParentInfo, [ numberParentInfo ] )
}

export const selectTrunkOptionList = ( state: RootState ) =>
  state.orders.trunkOptionList
export const useTrunkOptionList = () => {
  const trunkOptionList = useAppSelector( selectTrunkOptionList )
  return useMemo( () => trunkOptionList, [ trunkOptionList ] )
}

export const selectResellerOptionList = ( state: RootState ) =>
  state.orders.resellerOptionList
export const useResellerOptionList = () => {
  const resellerOptionList = useAppSelector( selectResellerOptionList )
  return useMemo( () => resellerOptionList, [ resellerOptionList ] )
}

export const selectSearchNumbers = (state: RootState) =>
  state.orders.searchNumbers
export const useSearchNumbers = () => {
  const searchNumbers = useAppSelector(selectSearchNumbers)
  return useMemo(() => searchNumbers, [searchNumbers])
}

export const selectNumbers = (state: RootState) => state.orders.selectedNumbers
export const useSelectedNumbers = () => {
  const numbers = useAppSelector(selectNumbers)
  return useMemo(() => numbers, [numbers])
}

export const selectPlaceOrder = (state: RootState) =>
  state.orders.placeOrderDetails
export const usePlaceOrderDetails = () => {
  const placeorderDetails = useAppSelector(selectPlaceOrder)
  return useMemo(() => placeorderDetails, [placeorderDetails])
}

export const selectGeneralInformation = (state: RootState) =>
  state.orders.generalInformation
export const useGeneralInformation = () => {
  const generalInformation = useAppSelector(selectGeneralInformation)
  return useMemo(() => generalInformation, [generalInformation])
}

export const selectCheckPortabilityNumber = (state: RootState) =>
  state.orders.checkPortabilityNumber
export const useCheckPortabilityNumber = () => {
  const checkPortabilityNumber = useAppSelector(selectCheckPortabilityNumber)
  return useMemo(() => checkPortabilityNumber, [checkPortabilityNumber])
}

export const selectPortnumberDetails = (state: RootState) =>
  state.orders.portNumberDetails
export const usePortNumberDetails = () => {
  const portNumberDetails = useAppSelector(selectPortnumberDetails)
  return useMemo(() => portNumberDetails, [portNumberDetails])
}
