import { Auth0Provider } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"

function AuthProvider({ children }: { children: any }) {
  const navigate = useNavigate()
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={import.meta.env?.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env?.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: import.meta.env?.VITE_AUTH0_AUDIENCE,
        redirect_uri: import.meta.env?.VITE_AUTH0_CALLBACK_URL
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthProvider
