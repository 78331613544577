import {
  allowedPortingValues,
  allowedSecureFaxValues,
  allowedUcaasValues
} from "../../constants/allowedRoles"
import usePartnerStatus from "../../hooks/usePartnerStatus"
import {
  getuserRolesList,
  useUserRolesList
} from "../../pages/userRoles/userRolesSlice"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { RootState } from "../../store/store"
import Button from "../Button/Button"
import { MenuList } from "./MenuItems"
import Sibarstyle from "./Sidebar.style"
import { Flex, message, Skeleton } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export interface MenuListTypes {
  id: string
  icon: React.ElementType
  title: string
  url: string
  target?: string
}

function Sidebar() {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const rolesList = useUserRolesList()
  const { loadingPartnerOnboarding } = usePartnerStatus()
  const { isPartnerOnboarding } = useAppSelector(
    (state: RootState) => state.partner
  )
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const approvedRolesList = Object.keys(rolesList?.data?.roles_list || []).map(
    (r) => r.toLowerCase()
  )
  const rolesData = rolesList?.data?.roles_list
  const ucaasValue = rolesData?.UCaaS
  const secureFaxValue = rolesData?.SecureFax
  const portingValue = rolesData?.Porting

  const fetchRoles = async () => {
    setIsLoading(true)
    try {
      const res = await dispatch(getuserRolesList()).unwrap()
      if (res?.error) {
        navigate("/server-error")
        message.error("An error occurred while fetching user data.")
      } else {
        return res
      }
    } catch (er: any) {
      message.error("An error occurred while fetching user data.")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const menuUpdatedList = useMemo(() => {
    if (isLoading) {
      return []
    }
    if (isPartnerOnboarding) {
      return MenuList.filter((item) => item.id === "dashboard")
    }
    let updateData =
      MenuList.filter(
        (item) => item.title !== "Resellers" && item?.title !== "Users"
      ) || []
    if (rolesData?.Customer === "Super User") {
      updateData = MenuList?.filter(
        (item) => item?.title !== "Customers" && item?.title !== "Users"
      )
    }
    if (
      [
        "Office Manager",
        "Site Manager",
        "Customer Site Manager Read Only"
      ].includes(rolesData?.Customer)
    ) {
      updateData = MenuList?.filter(
        (item) => item?.title !== "Customers" && item?.title !== "Resellers"
      )
    }
    if (!rolesData?.UCaaS || rolesData?.UCaaS === "No Portal") {
      updateData = updateData.filter((item) => item?.title !== "UCaaS")
    }
    if (!rolesData?.AlertBlast) {
      updateData = updateData.filter((item) => item?.title !== "AlertBlast")
    }
    if (!rolesData?.Billing) {
      updateData = updateData.filter((item) => item?.title !== "Billing")
    }
    if (!rolesData?.Teams) {
      updateData = updateData.filter((item) => item?.title !== "Teams")
    }
    if (!rolesData?.LMS) {
      updateData = updateData.filter((item) => item?.title !== "Training")
    }
    return updateData
  }, [
    isLoading,
    isPartnerOnboarding,
    rolesData?.Customer,
    rolesData?.UCaaS,
    rolesData?.AlertBlast,
    rolesData?.Billing,
    rolesData?.Teams,
    rolesData?.LMS
  ])

  const menuFilter = (x: { id: string }) =>
    // (rolesData?.Porting === "Reseller" ||
    // rolesData?.Porting === "Super User") &&
    x.id === "dashboard" ||
    ((allowedPortingValues.has(portingValue) ||
      allowedSecureFaxValues.has(secureFaxValue) ||
      allowedUcaasValues.has(ucaasValue)) &&
      x.id === "porting") ||
    (rolesData?.Customer === "Super User" && x.id === "resellers") ||
    (rolesData?.Customer === "Office Manager" && x.id === "users") ||
    approvedRolesList.includes(x.id.toLowerCase())

  return (
    <Sibarstyle>
      <div className="sidebar">
        {isLoading || loadingPartnerOnboarding ? (
          <Flex vertical align="center" gap={8} style={{ marginTop: "8px" }}>
            {Array.from(Array(5).keys()).map((index) => (
              <Skeleton.Avatar key={index} active size={60} shape="square" />
            ))}
          </Flex>
        ) : (
          <ul className="sidebar__top">
            {rolesData &&
              menuUpdatedList
                ?.filter((x: MenuListTypes) => menuFilter(x))
                .map((val: MenuListTypes) => {
                  const Icon = val?.icon
                  const array = location?.pathname?.split("/")
                  const new_url = val?.url?.split("/")

                  // const isSelected =
                  //   val?.url === `/${array[1]}` || new_url[1] === `${array[1]}` ||
                  //   (location.pathname === "/" && val?.id === "dashboard")

                  const isSelected =
                    (array[1] &&
                      (val?.url === `/${array[1]}` ||
                        new_url[1] === `${array[1]}`)) ||
                    (location.pathname === "/" && val?.id === "dashboard")

                  return (
                    <li key={val.id}>
                      <Button
                        target={val?.target}
                        type="link"
                        size="small"
                        href={val?.url}
                        icon={<Icon />}
                        className={
                          isSelected
                            ? "active"
                            : val?.target
                            ? "target-style"
                            : ""
                        }
                      >
                        {val?.title}
                      </Button>
                    </li>
                  )
                })}
          </ul>
        )}
      </div>
    </Sibarstyle>
  )
}

export default Sidebar
