/* eslint-disable @typescript-eslint/no-explicit-any */
// PROJECT IMPORTS
import { CREATE_ORDER, FETCH_RESELLER, ORDERS, ORDERS_NOTES, ORDERS_DETAILS, SEARCH_NUMBERS, SEARCH_PORT_NUMBERS, SEARCH_PARENT_INFO, TRUNK_LIST, UPLOAD_PORT_DOCUMENT, VLIDATE_PORT_ADDRESS, UPDATE_PORTIN_ORDER, GET_LOA_FILES, RETRIVE_LOA_FILES, DELETE_LOA_FILES, PORT_ORDER_NOTES, PORTIN_DRAFT_TO_SUBMIT, PORTIN_CANCEL } from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"

/* ============================== ORDERS SERVICES ============================== */

// eslint-disable-next-line import/prefer-default-export
export const orderListGet = ( params: any ) => {
  return apiInstance.get( ORDERS, { params } )
}

export const orderDetailsGet = ( params: any ) => {
  return apiInstance.post( ORDERS_DETAILS, {}, { params } )
}

export const ordernotesGet = ( params: any ) => {
  return apiInstance.get( ORDERS_NOTES, { params } )
}

export const searchNumberList = ( payload: any ) => {
  return apiInstance.post( SEARCH_NUMBERS, payload )
}

export const searchNumberPortList = ( payload: any ) => {
  return apiInstance.post( SEARCH_PORT_NUMBERS, payload )
}

export const searchPortDetails = ( params: any ) => {
  return apiInstance.post( ORDERS_DETAILS, {}, { params } )
}

export const searchParentInfo = ( params: any ) => {
  return apiInstance.get( SEARCH_PARENT_INFO, { params } )
}

export const trunkList = () => {
  return apiInstance.post( TRUNK_LIST )
}

export const resellerList = () => {
  return apiInstance.post( FETCH_RESELLER )
}

export const createOrder = ( payload: any ) => {
  return apiInstance.post( CREATE_ORDER, payload )
}

//  Shrikant - API functions - start
export const uploadPortDocument = ( payload: any ) => {
  return apiInstance.post( UPLOAD_PORT_DOCUMENT, payload )
}

export const validatePortAddress = ( payload: any ) => {
  return apiInstance.post( VLIDATE_PORT_ADDRESS, payload )
}

export const updatePortInOrder = ( payload: any ) => {
  return apiInstance.post( UPDATE_PORTIN_ORDER, payload )
}

export const getLOAFiles = ( params: any ) => {
  return apiInstance.get( GET_LOA_FILES, { params } )
}

export const retriveLOAFiles = ( params: any ) => {
  return apiInstance.get( RETRIVE_LOA_FILES, { params } )
}

export const deleteLOAFiles = ( params: any ) => {
  return apiInstance.delete( DELETE_LOA_FILES, { params } )
}

export const getOrderNotes = ( params: any ) => {
  return apiInstance.get( PORT_ORDER_NOTES, { params } )
}

export const portInDraftToSubmit = ( params: any ) => {
  return apiInstance.post( PORTIN_DRAFT_TO_SUBMIT, {}, { params } )
}

export const postPortInOrderNotes = ( payload: any ) => {
  return apiInstance.post( PORT_ORDER_NOTES, payload )
}

export const cancelPortInRequest = ( payload: any ) => {
  return apiInstance.post( PORTIN_CANCEL, payload )
}
//  Shrikant - API functions - end