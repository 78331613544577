import React from "react"

function SplashFaviconIcon() {
  return (
    <span className="anticon anticon-splashfavicon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 228 228"
      >
        <path
          d="M114 227.333C176.592 227.333 227.333 176.592 227.333 114C227.333 51.4078 176.592 0.666748 114 0.666748C51.4076 0.666748 0.666504 51.4078 0.666504 114C0.666504 176.592 51.4076 227.333 114 227.333Z"
          fill="#38A6DE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.0347 68.45L159.579 42.761L181.003 138.18L84.3614 159.79L71.3148 178.956L46.0347 68.45Z"
          fill="white"
        />
        <path
          d="M155.722 32.1673L45.546 57.1547C37.9727 58.8738 33.2474 66.7771 35.0502 74.7221L63.8754 201.833L85.5221 172.697L181.927 150.832C189.5 149.113 194.225 141.21 192.423 133.265L172.769 43.4855C170.966 35.5405 163.295 30.4482 155.722 32.1673ZM178.651 136.391L82.2466 158.257L71.7507 172.702L48.8216 71.5952L158.998 46.6078L178.651 136.387V136.391Z"
          fill="#18508D"
        />
        <path
          d="M135.242 98.5851V83.457C135.242 81.0781 133.295 79.136 130.921 79.136H79.0549C76.676 79.136 74.7339 81.0828 74.7339 83.457V126.681C74.7339 129.06 76.6807 131.002 79.0549 131.002H130.921C133.3 131.002 135.242 129.055 135.242 126.681V111.553L152.53 128.841V81.2965L135.242 98.5851Z"
          fill="#38A6DE"
        />
      </svg>
    </span>
  )
}

export default SplashFaviconIcon
