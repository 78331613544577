import { Button } from "antd"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"

export const ButtonStyle = styled(Button)`
  padding: 20px 50px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.type === "primary" &&
    !props.danger &&
    css`
      color: ${props.theme.textcolordwhite3};
      &:hover {
        background-color: ${props.theme.bgcolordskyblue};
      }
      &:focus {
        background-color: ${props.theme.bgcolordarkblue};
      }
      &:disabled {
        background-color: ${props.theme.bgcolordskyblue};
        opacity: 0.5;
        border: none;
        color: ${props.theme.textcolordwhite3};
      }
    `}

  ${(props) =>
    props.type === "default" &&
    css`
      border-color: ${props.theme.primary};
      color: ${props.theme.primary};
      &:hover {
        background-color: ${props.theme.bgcolordwhite};
        color: ${props.theme.textcolordskyblue};
        border-color: ${props.theme.bordercolordskyblue};
      }
      &:focus {
        background-color: ${props.theme.bgcolordwhite};
        color: ${props.theme.textcolordarkblue};
        border-color: ${props.theme.bordercolordarkblue};
      }
    `}  

     ${(props) =>
    props.type === "text" &&
    css`
      color: ${props.theme.primary};
      padding: 0 !important;
      border: none;
      background-color: transparent;
      &.grey-btntext {
        color: #757575;
        padding: 0 10px !important;
      }
      &.ant-btn-text {
        height: auto;
        padding: 0;
        &:hover {
          background-color: transparent;
        }
      }
      &:hover,
      &:active {
        background-color: transparent;
        color: ${props.theme.textcolordskyblue};
      }
      &:focus {
        background-color: transparent;
        color: ${props.theme.textcolordarkblue};
      }
    `}

         ${(props) =>
    props.type === "link" &&
    css`
      color: ${props.theme.primary};
      border: none;
      background-color: transparent;
      .ant-typography & {
        font-size: inherit;
      }
      &.ant-btn-link {
        height: auto;
        padding: 0;
      }
      &.ant-btn-sm {
        padding: 0 !important;
      }
      &:hover,
      &:active {
        background-color: transparent;
        color: ${props.theme.textcolordskyblue};
        text-decoration: underline;
      }
      &:focus {
        background-color: transparent;
        color: ${props.theme.textcolordarkblue};
      }
    `}

      ${(props) =>
    props.type === "black" &&
    css`
      border-color: ${props.theme.bordercolordblack};
      color: ${props.theme.textcolordblack};
      &:hover {
        border-color: ${props.theme.bordercolordblack};
      }
      &:focus {
        border-color: ${props.theme.bordercolordblack};
      }
    `}
      ${(props) =>
    props.type === "red" &&
    css`
      border-color: ${props.theme.bordercolordanger};
      color: ${props.theme.bordercolordanger};

      &:hover {
        border-color: ${props.theme.bordercolordanger};
      }
      &:focus {
        border-color: ${props.theme.bordercolordanger};
      }
    `}
          ${(props) =>
    props.type === "gray" &&
    css`
      border-color: ${props.theme.bordercolorsharpgrey};
      color: ${props.theme.textcolordblack};
      background-color: ${props.theme.colordwhitedark};
      &.text {
        border: transparent;
        background-color: transparent;
        color: ${props.theme.colortextgray};
      }
      &:hover {
        border-color: ${props.theme.bordercolordblack};
      }
      &:focus {
        border-color: ${props.theme.bordercolordblack};
      }
    `}
    ${(props) =>
    props.size === "large" &&
    css`
      padding: 0 50px;
      .ant-btn-text {
        height: 20px;
      }
    `}
     ${(props) =>
    props.className === "h-48" &&
    css`
      &.h-48 {
        height: 48px;
      }
    `}

    ${(props) =>
    props.className === "h-40" &&
    css`
      &.h-40 {
        height: 40px;
        width: 40px;
      }
    `}
    ${(props) =>
    props.className === "socialbtn" &&
    css`
      &.socialbtn {
        background: ${(props) => props.theme.socialbtnbg};
        border: 1px solid transparent;
        border-radius: 2px;
        .anticon {
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${(props) => props.theme.textcolordwhite3};
          font-size: 18px;
        }
        color: ${(props) => props.theme.socialbtncolor};
        filter: drop-shadow(rgba(0, 0, 0, 0.082) 0px 0px 1px)
          drop-shadow(rgba(0, 0, 0, 0.17) 0px 1px 1px);
        font-weight: 500;
        padding-left: 0px !important;
        padding-right: 8px !important;
      }
    `}
    ${(props) =>
    props.className === "onlyicon" &&
    css`
      &.ant-btn-icon-only {
        &.onlyicon {
          background: transparent;
          padding: 0;
          border: none;
          height: 14px;
          width: 14px;
        }
      }
    `}

      ${(props) =>
    props.className === "h-23" &&
    css`
      &.h-23 {
        height: 23px;
        padding: 0 14px;
        font-size: 11px;
        border-radius: 4px;
      }
    `}
      ${(props) =>
    props.className === "h-20" &&
    css`
      &.h-20 {
        height: 20px;
        /* font-size: 11px; */
      }
    `}
     ${(props) =>
    props.className === "xsmall" &&
    css`
      &.ant-btn-sm {
        &.xsmall {
          height: 32px;
          font-size: 12px;
        }
      }
    `}
    ${(props) =>
    props.size === "middle" &&
    css`
      padding: 0 40px;
    `}
      ${(props) =>
    props.size === "small" &&
    css`
      &.ant-btn-sm {
        font-size: 14px;
        padding: 0 24px;
      }
      &.h-32 {
        height: 32px;
        font-size: 12px;
        min-width: 70px;
      }
      .ant-btn-text {
        height: 20px;
      }
    `}
     &.ant-btn-icon-only {
    padding: 0 20px;
  }
`

export const LinkStyle = styled(Link)`
  &.linkstyle {
    color: ${(props) => props.theme.primary};
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 5px;
    &.skipLink {
      display: inline-block;
      margin-left: 5px;
    }
    &.primary {
      font-size: 14px;
      padding: 11px 24px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.textcolordwhite3};
      &:hover {
        background-color: ${(props) => props.theme.bgcolordskyblue};
        color: ${(props) => props.theme.textcolordwhite3};
        text-decoration: none;
      }
      text-decoration: none;
    }
    &:focus {
      background-color: ${(props) => props.theme.primary};
    }
    &:disabled {
      background-color: ${(props) => props.theme.primary};
      opacity: 0.5;
      border: none;
      color: ${(props) => props.theme.primary};
    }

    &:hover,
    &:active {
      background-color: transparent;
      color: ${(props) => props.theme.textcolordskyblue};
      text-decoration: underline;
    }
    &:focus {
      background-color: transparent;
      color: ${(props) => props.theme.textcolordarkblue};
    }
  }
`
