import styled from "styled-components"

const SplashLoaderStyle = styled.div`
  background-color: #18508d;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 50%;
  }
  .anticon {
    font-size: 200px;
    margin-top: 50px;
    margin-bottom: 30px;
    &.anticon-splashfavicon {
      font-size: 140px;
      margin-bottom: 50px;
      margin-top: 0;
    }
  }
  .loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(1.5);
  }
  .loading div {
    position: absolute;
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
    border: 1px solid #fff;
  }
  .loading div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .loading div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .loading div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .loading div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .loading div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .loading div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .loading div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .loading div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .loading div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .loading div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .loading div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .loading div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
      background: #fff;
    }
  }
`
export default SplashLoaderStyle
