// import { useAuth0 } from "@auth0/auth0-react"
import { message } from "antd"
import axios from "axios"

const baseURL = import.meta.env.VITE_API_SERVER_URL

// eslint-disable-next-line import/prefer-default-export
export const apiInstance = axios.create({
  baseURL
})

apiInstance.interceptors.request.use(
  // eslint-disable-next-line func-names
  async function (config) {
    // const { logout } = useAuth0()
    const accessToken = localStorage.getItem("accessToken")
    // if (!accessToken) logout()
    config.headers.Authorization = accessToken
      ? `Bearer ${accessToken}`
      : undefined
    return config
  },
  // eslint-disable-next-line func-names
  function (error) {
    return Promise.reject(error)
  }
)

// Add a response interceptor
apiInstance.interceptors.response.use(
  // eslint-disable-next-line func-names
  function (response) {
    return response.data
  },
  // eslint-disable-next-line func-names
  function (error) {
    const { error: errorMessage } = error.response.data
    if (error.response.status === 500) {
      if (typeof errorMessage === "string") {
        message.error(error.response.data.error || "Internal server Error")
      }

      return error.response.data
    }
    if (error.response.status === 400) {
      if (typeof errorMessage === "string") {
        message.error(errorMessage || "Something Went Wrong")
      }

      return error.response.data
    }
    if (error.response.status === 409) {
      if (
        error.response.data.error != "Extension number is currently in use."
      ) {
        message.error(error.response.data.error || "Something Went Wrong")
      }
      return error.response
    }

    if (error.response.status === 504) {
      message.error(error.response.data.error || "Something Went Wrong")
      return error.response
    }

    if (error.response.status === 422) {
      if (typeof errorMessage === "string") {
        message.error = (error.response.data.error || "Something Went Wrong")
      }
      return error.response
    }
    if (
      typeof error.response.data.message === "string" ||
      typeof error.response.data.error === "string"
    ) {
      message.error(
        error.response.data.message ||
          error.response.data.error ||
          "Something Went Wrong"
      )
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < error?.response?.data?.message?.length; i++) {
        message.error(error.response.data.message[i])
      }
    }
    return Promise.reject(error)
  }
)
