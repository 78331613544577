import AlertCircleIcon from "../Icons/AlertCircle"
import AlertIcon from "../Icons/AlertIcon"
import BookMeetings from "../Icons/BookMeeting"
import Customer from "../Icons/CustomerIcon"
import Numbers from "../Icons/NumbersIcon"
import CallAdd from "../Icons/CallAdd"
import ContactBookIcon from "../Icons/ContactBookIcon"
import DashboardIcon from "../Icons/Dashboard"
import DollarIcon from "../Icons/DollarIcon"
import FaxIcon from "../Icons/Fax"
import Partner from "../Icons/Partner"
import SupportIcon from "../Icons/SupportIcon"
import TeamsIcon from "../Icons/TeamsIcon"
import TrainingIcon from "../Icons/TrainingIcon"

/* ============================== MENU ITEMS ============================== */

interface MenuListTypes {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any
  title: string
  url: string
  target?: string
}

// eslint-disable-next-line import/prefer-default-export
export const MenuList: MenuListTypes[] = [
  {
    id: "dashboard",
    icon: () => <DashboardIcon />,
    title: "Home",
    url: "/dashboard"
  },
  // {
  //   id: "customer",
  //   icon: () => <BookMeetings />,
  //   title: "Inventory",
  //   url: "/inventory"
  // },
  {
    id: "users",
    icon: () => <Customer />,
    title: "Users",
    url: "/users"
  },
  {
    id: "resellers",
    icon: () => <Customer />,
    title: "Resellers",
    url: "/reseller"
  },
  {
    id: "customer",
    icon: () => <Customer />,
    title: "Customers",
    url: "/customer"
  },
  {
    id: "porting",
    icon: () => <Numbers />,
    title: "Numbers",
    url: "/numbers"
  },
  // {
  //   id: "porting",
  //   icon: () => <CallAdd />,
  //   title: "Orders",
  //   url: "/orders"
  // },
  {
    id: "securefax",
    icon: () => <FaxIcon />,
    title: "SecureFax",
    url: "/secure-fax"
  },
  {
    id: "partner",
    icon: () => <Partner />,
    title: "Partner",
    url: "/partner/deals-pipeline"
  },
  {
    id: "lms",
    icon: () => <TrainingIcon />,
    title: "Training",
    url: "https://onecloud.com/resources/videos/",
    target: "_blank"
  },
  {
    id: "teams",
    icon: () => <TeamsIcon />,
    title: "Teams",
    url: "/teams"
  },
  // {
  //   id: "billing",
  //   icon: () => <DollarIcon />,
  //   title: "Billing",
  //   url: "/billing"
  // },
  // {
  //   id: "support",
  //   icon: () => <SupportIcon />,
  //   title: "Support",
  //   url: "/support"
  // },
  {
    id: "ucaas",
    icon: () => <ContactBookIcon />,
    title: "UCaaS",
    url: "/ucaas"
  },
  {
    id: "alertBlast",
    icon: () => <AlertIcon />,
    title: "AlertBlast",
    url: "https://myalertblast.com/admin",
    target: "_blank"
  }
]
