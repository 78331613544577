import React from "react"

function LogoutIcon() {
  return (
    <span className="anticon anticon-logout">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          stroke="Currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M13.08 10.965L15 9.045l-1.92-1.92M7.32 9.045h7.627M8.82 15c-3.315 0-6-2.25-6-6s2.685-6 6-6"
        />
      </svg>
    </span>
  )
}

export default LogoutIcon
