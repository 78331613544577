import { Button } from "antd"
import NotFoundIcon from "../../components/Icons/NotFoundIcon"
import NotFoundWrapper from "../notFound/NotFound.style"
import { useNavigate } from "react-router-dom"

const InternalServerError = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/")
  }
  console.log("Internal Server Error")
  return (
    <NotFoundWrapper>
      <div className="contentwrapper">
        <NotFoundIcon />
        <div className="description">An error occurred while fetching user data.</div>
        <Button type="link" size="small" onClick={handleClick}>
          Click here to try again
        </Button>
      </div>
    </NotFoundWrapper>
  )
}

export default InternalServerError
