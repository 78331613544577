/* eslint-disable react/jsx-filename-extension */
import { useState } from "react"
import { Avatar, Col, Modal, Row } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useAuth0 } from "@auth0/auth0-react"
import { Heading6 } from "../../config/global.style"
import Button from "../Button/Button"
import {
  AccountAction,
  AvatarProfile,
  MailIds,
  ProfileWrapper,
  UserDetails,
  Wrapper
} from "./UserProfile.style"
import LogoutIcon from "../Icons/LogoutIcon"
import DefaultUserIcon from "../Icons/DefaultUser"

export interface MeetingIds {
  pk: number
  room_id: string
  logo: string
  is_current: boolean
}

function UserProfile() {
  const { user, logout } = useAuth0()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleLogout = async () => {
    localStorage.removeItem("accessToken")
    logout()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const openLogoutModel = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <Wrapper>
        <ProfileWrapper>
          <AvatarProfile>
            <div className="badge-wrapper">
              <Avatar
                size="large"
                icon={<DefaultUserIcon />}
                shape="square"
                src={user?.picture || <DefaultUserIcon />}
              />
            </div>

            <UserDetails>
              <Heading6 className="heading6">{user?.name || ""}</Heading6>
              <MailIds>
                <span className="usermail">{user?.email || ""}</span>
              </MailIds>
            </UserDetails>
          </AvatarProfile>
        </ProfileWrapper>
        <AccountAction>
          <ul>
            <li>
              <Button
                icon={<LogoutIcon />}
                type="text"
                className="ctalinks linkdanger"
                size="small"
                // onClick={() => setIsModalOpen(true)}
                onClick={openLogoutModel}
              >
                Logout
              </Button>
            </li>
          </ul>
        </AccountAction>
      </Wrapper>
      <Modal
        centered
        closeIcon={false}
        open={isModalOpen}
        wrapClassName="logoutmodal"
        footer={[
          <Row>
            <Col xs={24} lg={24}>
              <div
                className="footerright"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button type="gray" size="small" onClick={handleCancel}>
                  No
                </Button>

                <Button
                  onClick={handleLogout}
                  type="primary"
                  // disabled={joinDisable}
                  size="small"
                >
                  Yes
                </Button>
              </div>
            </Col>
          </Row>
        ]}
      >
        <ExclamationCircleOutlined style={{ color: "red" }} />{" "}
        <div className="logouttext">Are you sure, you want to logout?</div>
      </Modal>
    </>
  )
}

export default UserProfile
