/* eslint-disable @typescript-eslint/no-explicit-any */
// PROJECT IMPORTS
import {
  ADD_USER_NUMBER,
  ADD_USER_EMAIL,
  DOMAIN,
  DOMAIN_SITE,
  LICENSE_TYPES,
  REMOVE_USER_NUMBER,
  REMOVE_USER_EMAIL,
  SECURE_FAX_CREATE,
  SECURE_FAX_DOMAIN,
  SECURE_FAX_DOMAIN_NUMBER_LIST,
  SECURE_FAX_NUMBER,
  SECURE_FAX_NUMBER_CREATE,
  SECURE_FAX_NUMBER_DELETE,
  SECURE_FAX_NUMBER_UPDATE,
  SECURE_FAX_SEARCH_USER,
  SECURE_FAX_UPDATE,
  SECURE_FAX_USER,
  SENT_FAX_LOG,
  SENT_FAX_UUID,
  TIME_ZONE
} from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"

/* ============================== SECUREFAX ADMIN SERVICES ============================== */

export const secureFaxListGet = (params: any) => {
  return apiInstance.get(SECURE_FAX_USER, { params })
}

export const secureFaxListNumberGet = (params: any) => {
  return apiInstance.get(SECURE_FAX_NUMBER, { params })
}

export const secureFaxListNumberDelete = (number: any) => {
  return apiInstance.delete(SECURE_FAX_NUMBER_DELETE + `/${number}`)
}

export const secureFaxCreate = (payload: any) => {
  return apiInstance.post(SECURE_FAX_CREATE, payload)
}

export const secureFaxUpdate = (payload: any) => {
  return apiInstance.put(SECURE_FAX_UPDATE, payload)
}

export const domainListGet = (payload: any) => {
  return apiInstance.post(DOMAIN, payload)
}

export const domainGet = () => {
  return apiInstance.get(SECURE_FAX_DOMAIN)
}

export const timeZoneListGet = () => {
  return apiInstance.get(TIME_ZONE)
}

export const licenseListGet = () => {
  return apiInstance.get(LICENSE_TYPES)
}

export const domainSitesGet = (params: any) => {
  return apiInstance.get(DOMAIN_SITE, { params })
}

export const domainNmbersGet = (domain: string) => {
  return apiInstance.get(SECURE_FAX_DOMAIN_NUMBER_LIST + `/${domain}`)
}

export const userOptionGet = (domain: string) => {
  return apiInstance.get(SECURE_FAX_SEARCH_USER + `/${domain}`)
}

export const secureFaxNumberCreate = (payload: any) => {
  return apiInstance.post(SECURE_FAX_NUMBER_CREATE, payload)
}

export const secureFaxNumberUpdate = (number: string, payload: any) => {
  return apiInstance.put(SECURE_FAX_NUMBER_UPDATE + `/${number}`, payload)
}

export const logListGet = (params: any) => {
  return apiInstance.get(SENT_FAX_LOG, { params })
}

export const logUuidGet = (uuid: string) => {
  return apiInstance.get(SENT_FAX_UUID + `/${uuid}`)
}

export const addUserNumber = (id: string, number: string) => {
  return apiInstance.put(ADD_USER_NUMBER + `/${id}` + `/${number}`)
}

export const removeUserNumber = (id: string, number: string) => {
  return apiInstance.delete(REMOVE_USER_NUMBER + `/${id}` + `/${number}`)
}

export const addUserEmail = (payload: any) => {
  return apiInstance.put(ADD_USER_EMAIL, payload)
}

export const removeUserEmail = (payload: any) => {
  return apiInstance.delete(REMOVE_USER_EMAIL, payload)
}
