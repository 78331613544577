// PROJECT IMPORTS
import {
  GET_ALL_DEALS,
  GET_DEALS_DATA,
  UPDATE_DEALS_DATA,
  GET_DEALS_TIMELINE_DATA,
  CREATE_PARTNERS_DEAL_ACCOUNT,
  CREATE_PARTNERS_DEAL_CONTACT,
  CREATE_PARTNERS_DEAL,
  UPDATE_PARTNERS_CONTACT,
  UPDATE_PARTNERS_DEAL,
  UPDATE_PARTNERS_DEAL_ACCOUNT,
  GET_PARTNERS_DETAILS,
  PARTNER_ACCOUNT,
  UPDATE_PARTNER_ACCOUNT,
  GET_ALL_PARTNER_CONTACTS,
  UPDATE_PARTNERS_ACCOUNT_CONTACTS,
  CREATE_PARTNER_CONTACT,
  PARTNER_RESOURCE_TAG,
  PARTNER_CREATE_RESOURCE_TAG,
  PARTNER_DELETE_RESOURCE_TAG,
  PARTNER_UPDATE_RESOURCE_TAG,
  PARTNER_GET_RESOURCE_TAG,
  PARTNER_GET_RESOURCE_TAG_FILTER,
  PARTNER_UPLOAD_ASSETS,
  GET_ASSET_MEDIA_URL,
  DELETE_ASSET,
  PARTNER_UPDATE_ASSET_DOCUMENT,
  PARTNER_GET_ASSETS,
  GET_PARTNER_ACCOUNT_DETAILS,
  GET_PARTNER_ACCOUNTS,
  PARTNER_RESOURCE_SINGLE_DELETE,
  GET_SINGLE_PARTNER_CONTACTS_DETAILS,
  GET_PARTNER_ONBOARDING_DETAILS,
  GET_PARTNER_EMBEDED_SIGNING_URL,
  POST_PARTNER_ONBOARDING_DATA,
  GET_PARTNER_ONBOARDING_DOCUMENT_STATUS,
  GET_PARTNER_ACCOUNT_ONBOARDING_DETAILS,
  VALIDATE_PARTNER_IDENTIFIER,
  GET_PARTNER_LEAD_ONBOARDING_DETAILS,
  GET_PARTNER_LEAD_EMBEDED_SIGNING_URL,
  GET_PARTNER_LEAD_ONBOARDING_DOCUMENT_STATUS,
  POST_PARTNER_LEAD_ONBOARDING_DATA,
  GET_PARTNER_LEAD_ACCOUNTS,
  PARTNER_LEAD_ACCOUNT,
  GET_LEAD_ACCOUNT_DETAILS,
  UPDATE_PARTNER_LEAD_ACCOUNT
} from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"
import { accountFilterType } from "./Partner"

/* ============================== PARTNER SERVICES ============================== */

export const registerDealsListGet = (params: any) => {
  return apiInstance.get(GET_ALL_DEALS, { params })
}

export const dealDataGet = (params: any) => {
  return apiInstance.get(`${GET_DEALS_DATA}/${params?.id}`)
}

export const getPartnerOnboardingDetails = (partner_account_id: string) => {
  return apiInstance.get(
    `${GET_PARTNER_ONBOARDING_DETAILS(partner_account_id)}`
  )
}

export const getPartnerLeadOnboardingDetails = (partner_lead_id: string) => {
  return apiInstance.get(
    `${GET_PARTNER_LEAD_ONBOARDING_DETAILS(partner_lead_id)}`
  )
}

export const getPartnerEmbededSigningUrl = (
  partner_account_id: string,
  params: any
) => {
  return apiInstance.get(
    `${GET_PARTNER_EMBEDED_SIGNING_URL(partner_account_id)}`,
    params
  )
}

export const getPartnerLeadEmbededSigningUrl = (
  partner_lead_id: string,
  params: any
) => {
  return apiInstance.get(
    `${GET_PARTNER_LEAD_EMBEDED_SIGNING_URL(partner_lead_id)}`,
    params
  )
}

export const getPartnerAccountOnboardingDetails = (params: any) => {
  return apiInstance.get(
    GET_PARTNER_ACCOUNT_ONBOARDING_DETAILS(params.account_id)
  )
}

export const getPartnerDocumentStatus = (
  partner_account_id: string,
  params: any
) => {
  return apiInstance.get(
    `${GET_PARTNER_ONBOARDING_DOCUMENT_STATUS(partner_account_id)}`,
    params
  )
}

export const getPartnerLeadDocumentStatus = (
  partner_lead_id: string,
  params: any
) => {
  return apiInstance.get(
    `${GET_PARTNER_LEAD_ONBOARDING_DOCUMENT_STATUS(partner_lead_id)}`,
    params
  )
}

export const validatePartnerIdentifierPost = (data: {
  identifier: string
  partner_id: number
}) => {
  return apiInstance.post(VALIDATE_PARTNER_IDENTIFIER, data)
}

export const dealTimeLineDataGet = (params: any) => {
  return apiInstance.get(GET_DEALS_TIMELINE_DATA(params?.id))
}

export const dealDataUpdate = ({ id, ...payload }: any) => {
  return apiInstance.put(UPDATE_DEALS_DATA(id), payload)
}

export interface IcreatePartnersAccountBody {
  account_name: string
  website: string
  phone: string
  state: string
  address: string
  country: string
  zip_code: string
  city: string
  is_partner: boolean
  // employees: string,
  // email_domain: string,
  // partner_account_type: string,
  // create_reseller: boolean,
  // reseller: {
  //   reseller_domain: string,
  //   reseller_description: string
  // }
}

export const createPartnersDealAccountPost = (
  data: IcreatePartnersAccountBody
) => {
  return apiInstance.post(CREATE_PARTNERS_DEAL_ACCOUNT, data)
}

export interface IcreatePartnersContactBody {
  contact_name: string
  first_name: string
  last_name: string
  title: string
  phone: string
  email: string
  address: string
  city: string
  state: string
  zip_code: string
  country: string
  // account_id: string
  is_partner: boolean
}
// export const createPartnersDealContactPost = ( data: IcreatePartnersContactBody ) => {
export const createPartnersDealContactPost = (
  account_number: any,
  data: IcreatePartnersContactBody
) => {
  // return apiInstance.post( CREATE_PARTNERS_DEAL_CONTACT, data )
  return apiInstance.post(CREATE_PARTNERS_DEAL_CONTACT(account_number), data)
}

export interface IcreatePartnersDealBody {
  deal_name: string
  description: string
  number_of_users: number
  account_id: string
  contact_id: string
  competitor: string
  region: string
  product_interest: string
  // annual_deal_value: number
  total_deal_value: number
  term_length: number
  closing_date: string
}

export interface AccountBodyType {
  account_name: string
  phone: string
  website: string
  employees: string
  partner_account_type: string
}

export const createPartnersDealPost = (data: IcreatePartnersDealBody) => {
  return apiInstance.post(CREATE_PARTNERS_DEAL, data)
}

export type IupdatePartnersAccountBody = IcreatePartnersAccountBody
export const partnerAccountGet = (params: accountFilterType) => {
  return apiInstance.get(GET_PARTNER_ACCOUNTS, { params })
}

export const partnerLeadAccountGet = (params: accountFilterType) => {
  return apiInstance.get(GET_PARTNER_LEAD_ACCOUNTS, { params })
}

export const partnerAccountCreate = (data: AccountBodyType) => {
  return apiInstance.post(PARTNER_ACCOUNT, data)
}

export const partnerLeadAccountCreate = (data: any) => {
  return apiInstance.post(PARTNER_LEAD_ACCOUNT, data)
}

export const partnerAccountPut = (accounts_id: any, data: AccountBodyType) => {
  return apiInstance.put(UPDATE_PARTNER_ACCOUNT(accounts_id), data)
}

export const partnerLeadAccountPut = (lead_id: string, data: any) => {
  return apiInstance.put(UPDATE_PARTNER_LEAD_ACCOUNT(lead_id), data)
}

export const updatePartnersAccountPut = (
  accounts_id: number,
  data: IupdatePartnersAccountBody
) => {
  return apiInstance.put(UPDATE_PARTNERS_DEAL_ACCOUNT(accounts_id), data)
}

export type IupdatePartnersContactBody = IcreatePartnersContactBody
export const updatePartnersContactPut = (
  account_id: any,
  contacts_id: any,
  data: IupdatePartnersContactBody
) => {
  return apiInstance.put(UPDATE_PARTNERS_CONTACT(account_id, contacts_id), data)
}

export const partnerContactsGet = (params: any) => {
  const { account_number, ...queryParams } = params
  return apiInstance.get(GET_ALL_PARTNER_CONTACTS(account_number), {
    params: queryParams
  })
}

export const partnerSingleContactDetailsGet = (params: any) => {
  const { account_number, contact_number } = params
  return apiInstance.get(
    GET_SINGLE_PARTNER_CONTACTS_DETAILS(account_number, contact_number)
  )
}

export const partnerContactsGetList = (account_number: any) => {
  return apiInstance.get(GET_ALL_PARTNER_CONTACTS(account_number))
}

// export const resllerCustomersGet = (params: any) => {
//   return apiInstance.get(RESELLER_CUSTOMERS(params.reseller), {params})
// }

// export const partnerContactPut = (contacts_id: string, data: any) => {
//   return apiInstance.put(UPDATE_PARTNER_CONTACT(contacts_id), data)
// }

export const updatePartnersAccountUpdate = (
  account_number: any,
  contact_number: any,
  data: any
) => {
  return apiInstance.put(
    UPDATE_PARTNERS_ACCOUNT_CONTACTS(account_number, contact_number),
    data
  )
}

export const partnerContactCreate = (account_number: any, data: any) => {
  return apiInstance.post(GET_ALL_PARTNER_CONTACTS(account_number), data)
}

export const createPartnerAccounts = (account_number: any, data: any) => {
  return apiInstance.post(CREATE_PARTNER_CONTACT(account_number), data)
}

export const partnerAccountDetailsGet = (accounts_id: any) => {
  return apiInstance.get(GET_PARTNER_ACCOUNT_DETAILS(accounts_id))
}

export const leadAccountDetails = (lead_id: any) => {
  return apiInstance.get(GET_LEAD_ACCOUNT_DETAILS(lead_id))
}

export interface IupdatePartnersDealBody {
  deal_name: string
  description: string
  number_of_users: string
  competitor: string
  region: string
  product_interest: string
  // annual_deal_value: number
  total_deal_value: number
  term_length: number
}

export const updatePartnersDealPut = (
  deals_id: number,
  data: IupdatePartnersDealBody
) => {
  return apiInstance.put(UPDATE_PARTNERS_DEAL(deals_id), data)
}

export const partnerDetailsGet = () => {
  return apiInstance.get(GET_PARTNERS_DETAILS)
}

// for get Resource Tag details
export const partnerResourceGet = (params: accountFilterType) => {
  return apiInstance.get(PARTNER_RESOURCE_TAG, { params })
}

// for create resource tag
export const partnerResourceTag = (data: any) => {
  return apiInstance.post(PARTNER_CREATE_RESOURCE_TAG, data)
}

// for update resource tag

export const partnerUpdateResourceTag = (id: any) => {
  return apiInstance.put(PARTNER_UPDATE_RESOURCE_TAG(id?.tag_id), id?.body)
}

// for delete resource tag
export const partnerDeleteResourceTag = (data: any) => {
  return apiInstance.delete(PARTNER_DELETE_RESOURCE_TAG, {
    data: data
  })
}

// partnerResourceOptions
export const partnerResourceOptions = (params: any) => {
  return apiInstance.get(PARTNER_GET_RESOURCE_TAG, { params })
}

// partnerResourceFilterOptions
export const partnerResourceFilterOptions = (params: any) => {
  return apiInstance.get(PARTNER_GET_RESOURCE_TAG_FILTER, { params })
}

// uploadPartnerDocument

export const uploadPartnerDocument = (payload: any) => {
  return apiInstance.post(PARTNER_GET_RESOURCE_TAG_FILTER, payload)
}

export const partnerOnboarding = (partner_account_id: string, payload: any) => {
  return apiInstance.post(
    POST_PARTNER_ONBOARDING_DATA(partner_account_id),
    payload
  )
}

export const partnerLeadOnboarding = (
  partner_lead_id: string,
  payload: any
) => {
  return apiInstance.post(
    POST_PARTNER_LEAD_ONBOARDING_DATA(partner_lead_id),
    payload
  )
}

// update Partner asset Document
export const updatePartnerAsset = (account_id: string) => {
  return apiInstance.get(PARTNER_UPDATE_ASSET_DOCUMENT(account_id))
}

// update assets document
export const updatePartnerDocument = (payload: any) => {
  return apiInstance.put(
    PARTNER_UPDATE_ASSET_DOCUMENT(payload?.asset_id),
    payload.body
  )
}

// PartnerAssestDocument
export const PartnerAssestDocument = (payload: any) => {
  return apiInstance.put(PARTNER_UPLOAD_ASSETS(payload?.asset_id), payload.body)
}

export const PartnerGetAssestData = (payload: any) => {
  return apiInstance.get(PARTNER_GET_ASSETS(payload?.asset_id))
}

// export const partnerUpdateResourceTag = (id: any) => {
//   return apiInstance.put(PARTNER_UPDATE_RESOURCE_TAG(id?.tag_id), id?.body)
// }

export const assetMediaURLGet = (asset_id: string) => {
  return apiInstance.get(GET_ASSET_MEDIA_URL(asset_id))
}

export const assetDelete = (asset_id: string) => {
  return apiInstance.delete(DELETE_ASSET(asset_id))
}

export const partnerResourceDeleteSingle = (payload: any) => {
  return apiInstance.delete(PARTNER_RESOURCE_SINGLE_DELETE(payload?.tag_id))
}
