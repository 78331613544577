import React from "react"

function DropIcon() {
  return (
    <span className="anticon anticon-dropdown ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          stroke="Currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M14.94 6.712l-4.89 4.89a1.49 1.49 0 01-2.1 0l-4.89-4.89"
        />
      </svg>
    </span>
  )
}

export default DropIcon
