import NotFoundWrapper from "./NotFound.style"
import NotFoundIcon from "../../components/Icons/NotFoundIcon"
import Button from "../../components/Button/Button"

export default function NotFound({ onHomeClick }: any) {
  return (
    <NotFoundWrapper>
      <div className="contentwrapper">
        <NotFoundIcon />
        <div className="title">404</div>
        <div className="description">
          The requested resource could not be found
        </div>
        <Button type="link" size="small" href="/" onClick={onHomeClick}>
          Go Home
        </Button>
      </div>
    </NotFoundWrapper>
  )
}
