/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { userRolesGet } from "./userRolesService"
// eslint-disable-next-line import/no-cycle
import { RootState } from "../../store/store"
import { useAppSelector } from "../../store/hooks"

interface InitialState {
  userRolesList: any
}

/* ============================== INVANTORY SLICE ============================== */

const initialState: InitialState = {
  userRolesList: {},
}

export const getuserRolesList = createAsyncThunk(
  "user-roles-list/roles",
  async () => {
    return await userRolesGet()
  }
)

const userRolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {
    clearuserRolesSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(
      getuserRolesList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userRolesList = action.payload?.data ? action.payload : []
      }
    )
  }
})

export default userRolesSlice.reducer
export const { clearuserRolesSlice } = userRolesSlice.actions

export const selectUserRolesList = (state: RootState) => state.userRoles.userRolesList
export const useUserRolesList = () => {
    const userRolesList = useAppSelector(selectUserRolesList);
    return useMemo(() => userRolesList, [userRolesList]);
}
