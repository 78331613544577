/* eslint-disable no-console */
/* @typescript-eslint/no-explicit-any */
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { Suspense } from "react"
import * as Sentry from "@sentry/react"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { store, persistor } from "./store/store"
import SplashLoader from "./components/SplasLoader/SplasLoader"
import reportWebVitals from "./reportWebVitals"
import AuthProvider from "./pages/login/authProvider"

const isSandbox = import.meta.env.VITE_SENTRY_ENVIRONMENT === "sandbox"

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracePropagationTargets: [import.meta.env.VITE_API_SERVER_URL],
  // Performance Monitoring
  tracesSampleRate: isSandbox ? 1.0 : 0.2, // Reduce sampling rate in production to limit performance overhead.
  // Session Replay
  replaysSessionSampleRate: isSandbox ? 1.0 : 0.05, // Capture more sessions in dev to test replay functionality.
  replaysOnErrorSampleRate: 1.0 // Always capture errors for replays
})

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!
const root = createRoot(container)
root.render(
  <Suspense fallback={<SplashLoader />}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </Suspense>
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
