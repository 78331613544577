import LoaderStyle from "./Loader.style"

function Loader() {
  return (
    <LoaderStyle>
      <div className="loading">
        {Array.from({ length: 12 }).map((item, index) => (
          <div key={`${item}-${index}`} />
        ))}
      </div>
    </LoaderStyle>
  )
}

export default Loader
