import React from "react"

function ContactBookIcon () {
  return (
    <span className="anticon anticon-contacts-book">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8613 1H5.85255C5.09665 1 4.37169 1.29997 3.83683 1.83414C3.30197 2.36832 3.00102 3.09294 3 3.84885V20.1512C3.001 20.9071 3.30196 21.6317 3.83683 22.1659C4.37169 22.7 5.09661 23 5.85255 23H19.8613C20.1703 23 20.4207 22.7496 20.4207 22.4407C20.4207 22.1317 20.1703 21.8814 19.8613 21.8814H5.85255C5.37914 21.9033 4.91754 21.7307 4.57479 21.4034C4.23204 21.0763 4.03827 20.6231 4.03827 20.1493C4.03827 19.6755 4.23203 19.2224 4.57479 18.8951C4.91754 18.568 5.37914 18.3953 5.85255 18.4172H19.8613C20.1703 18.4172 20.4207 18.1668 20.4207 17.8579V1.55941C20.4207 1.25045 20.1703 1.00009 19.8613 1.00009V1ZM4.11855 17.8917V3.84923C4.11955 3.38997 4.30266 2.94984 4.62759 2.62554C4.95269 2.3011 5.39316 2.11898 5.85242 2.11898H19.3021V17.2988H5.85242C5.22484 17.2996 4.6151 17.5081 4.11851 17.8918L4.11855 17.8917Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          d="M19.8621 19.5916H5.74511C5.43615 19.5916 5.18579 19.8421 5.18579 20.1509C5.18579 20.4598 5.43615 20.7102 5.74511 20.7102H19.8621C20.1711 20.7102 20.4214 20.4598 20.4214 20.1509C20.4214 19.8421 20.1711 19.5916 19.8621 19.5916Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          d="M13.4396 9.28947C13.8515 8.8395 14.0719 8.24656 14.0537 7.63682C14.0357 7.02705 13.7809 6.44825 13.3432 6.0233C12.9056 5.59831 12.3195 5.3606 11.7094 5.3606C11.0993 5.3606 10.5132 5.59831 10.0755 6.0233C9.63787 6.44828 9.38303 7.02709 9.36505 7.63682C9.3469 8.2466 9.5673 8.83954 9.97913 9.28947C9.17477 9.63272 8.48876 10.2042 8.00568 10.9331C7.52242 11.6621 7.26358 12.5167 7.2609 13.3912C7.21612 14.9422 8.27134 15.2555 9.65483 14.5098C10.2949 14.1862 10.9937 13.9946 11.7093 13.9468C12.425 13.9946 13.1238 14.1862 13.7638 14.5098C15.095 15.2033 16.1951 14.9982 16.1578 13.3912C16.1551 12.5167 15.8963 11.6621 15.413 10.9331C14.9299 10.2042 14.2439 9.63269 13.4395 9.28947H13.4396ZM10.4827 7.71588H10.4825C10.4815 7.39044 10.6101 7.0778 10.8398 6.84692C11.0693 6.6162 11.3813 6.48602 11.7069 6.48535C12.0325 6.48469 12.345 6.61353 12.5755 6.84342C12.8061 7.0733 12.9359 7.38544 12.9362 7.71101C12.9366 8.03645 12.8074 8.34891 12.5773 8.57912C12.3471 8.8095 12.035 8.93902 11.7094 8.93902C11.3846 8.93902 11.0732 8.81017 10.8433 8.58095C10.6133 8.35173 10.4836 8.0406 10.4826 7.71583L10.4827 7.71588ZM15.002 13.7791C14.7282 13.7024 14.4612 13.6027 14.2041 13.4808C13.4261 13.0946 12.5769 12.8726 11.7095 12.8283C10.8412 12.8761 9.99208 13.102 9.21487 13.492C8.95785 13.6138 8.69084 13.7135 8.41699 13.7903C8.38736 13.6619 8.37471 13.5303 8.37971 13.3988C8.37971 12.2092 9.01428 11.1099 10.0445 10.5151C11.0748 9.92015 12.3442 9.92015 13.3745 10.5151C14.4047 11.1099 15.0393 12.2092 15.0393 13.3988C15.0433 13.5266 15.0308 13.6544 15.002 13.7791L15.002 13.7791Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
      </svg>
    </span>
  )
}

export default ContactBookIcon
