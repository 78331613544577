import React from "react"

function NotFoundIcon() {
  return (
    <span className="anticon anticon-notfound ">
      <svg
        width="272"
        height="272"
        viewBox="0 0 272 272"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M133.26 208.519C181.452 208.519 220.519 169.452 220.519 121.26C220.519 73.0675 181.452 34 133.26 34C85.0675 34 46 73.0675 46 121.26C46 169.452 85.0675 208.519 133.26 208.519Z"
          fill="url(#paint0_linear_1066_76825)"
        />
        <path
          d="M193.706 162.704C193.647 162.704 193.589 162.704 193.53 162.699C180.455 161.629 171.45 150.449 171.072 149.973C170.321 149.026 170.478 147.646 171.426 146.89C172.373 146.139 173.753 146.296 174.504 147.243C174.607 147.376 182.311 156.871 192.98 158.226C196.441 154.795 199.353 145.716 199.033 142.967C199.029 142.942 199.029 142.918 199.029 142.888C198.955 142.005 198.793 138.98 199.662 137.173C200.187 136.083 201.498 135.622 202.588 136.147C203.678 136.673 204.14 137.983 203.614 139.073C203.398 139.525 203.295 141.155 203.398 142.505C203.884 147.022 199.971 158.423 195.052 162.238C194.664 162.547 194.192 162.704 193.706 162.704Z"
          fill="#474747"
        />
        <path
          d="M89.7278 150.808C85.8343 150.808 74.8119 149.85 65.8859 139.869C65.1936 139.093 65.1396 137.934 65.7582 137.1C69.3031 132.332 75.5582 121.074 76.5352 117.407C76.1326 116.709 75.406 115.708 74.5026 115.281C73.8054 114.952 73.3291 114.279 73.2506 113.513L72.8971 109.919C72.7792 108.711 73.6581 107.641 74.8659 107.523C76.0688 107.405 77.144 108.284 77.2619 109.492L77.5073 111.991C79.648 113.528 80.7183 115.924 80.8509 116.233C80.9982 116.582 81.0522 116.965 81.0129 117.343C80.5612 121.241 74.2423 132.514 70.3538 138.214C79.432 147.263 90.2826 146.438 90.7588 146.394C91.9568 146.296 93.0272 147.18 93.1352 148.382C93.2432 149.585 92.3594 150.651 91.1565 150.764C91.0534 150.769 90.5477 150.808 89.7278 150.808Z"
          fill="#474747"
        />
        <path
          d="M160.455 237.843H154.578C153.89 237.843 153.247 237.524 152.83 236.979C152.417 236.434 152.28 235.727 152.461 235.064C153.998 229.546 154.754 225.058 155.319 218.022C153.237 208.227 151.278 199.262 151.259 199.169C150.998 197.986 151.75 196.817 152.933 196.557C154.116 196.297 155.285 197.048 155.545 198.231C155.564 198.324 157.568 207.491 159.679 217.419C159.723 217.625 159.738 217.836 159.718 218.047C159.222 224.361 158.584 228.726 157.42 233.454H160.455C161.667 233.454 162.649 234.436 162.649 235.649C162.644 236.861 161.662 237.843 160.455 237.843Z"
          fill="#474747"
        />
        <path
          d="M115.015 237.843H111.696C111.062 237.843 110.458 237.568 110.041 237.087C109.624 236.611 109.437 235.973 109.525 235.344C110.09 231.318 112.982 223.988 116.055 216.618C117.15 206.897 118.049 198.55 118.059 198.467C118.186 197.264 119.266 196.385 120.474 196.522C121.677 196.65 122.551 197.73 122.418 198.938C122.409 199.022 121.491 207.56 120.381 217.423C120.356 217.63 120.307 217.831 120.224 218.022C118.044 223.241 115.564 229.389 114.43 233.459H115.019C116.232 233.459 117.214 234.441 117.214 235.653C117.209 236.861 116.227 237.843 115.015 237.843Z"
          fill="#474747"
        />
        <path
          d="M116.924 88.0415L91.3583 113.607C90.0719 141.941 90.18 171.704 91.3583 202.567H175.885C174.211 162.744 174.054 124.413 175.885 88.0464H116.924V88.0415Z"
          fill="white"
        />
        <path
          d="M175.888 202.999H91.3615C91.1258 202.999 90.9343 202.812 90.9245 202.577C89.697 170.536 89.697 140.591 90.9245 113.582C90.9294 113.474 90.9736 113.371 91.0521 113.292L116.617 87.7273C116.701 87.6438 116.809 87.5996 116.927 87.5996H175.893C176.011 87.5996 176.129 87.6487 176.212 87.7371C176.296 87.8255 176.34 87.9433 176.335 88.0611C174.572 123.087 174.572 160.539 176.335 202.542C176.34 202.66 176.296 202.778 176.212 202.866C176.124 202.955 176.006 202.999 175.888 202.999ZM91.7837 202.125H175.432C173.694 160.461 173.694 123.279 175.427 88.4785H117.103L91.7886 113.793C90.5759 140.62 90.5759 170.334 91.7837 202.125Z"
          fill="#474747"
        />
        <path
          d="M160.733 190.4H151.513C151.331 190.4 151.184 190.253 151.184 190.071C151.184 189.889 151.331 189.742 151.513 189.742H160.733C160.915 189.742 161.062 189.889 161.062 190.071C161.062 190.253 160.915 190.4 160.733 190.4Z"
          fill="#474747"
        />
        <path
          d="M147.695 190.4H106.516C106.335 190.4 106.188 190.253 106.188 190.071C106.188 189.889 106.335 189.742 106.516 189.742H147.695C147.876 189.742 148.024 189.889 148.024 190.071C148.024 190.253 147.876 190.4 147.695 190.4Z"
          fill="#474747"
        />
        <path
          d="M121.869 141.17C121.77 139.034 120.558 137.341 119.154 137.39C117.749 137.439 116.694 139.216 116.787 141.352C116.885 143.488 118.098 145.182 119.502 145.132C120.906 145.078 121.967 143.306 121.869 141.17Z"
          fill="#64C4F5"
        />
        <path
          d="M151.509 140.497C151.411 138.362 150.198 136.668 148.794 136.717C147.39 136.766 146.334 138.543 146.428 140.679C146.526 142.815 147.739 144.509 149.143 144.46C150.547 144.41 151.607 142.638 151.509 140.497Z"
          fill="#64C4F5"
        />
        <path
          d="M129.27 149.929C137.042 147.155 143.047 150.415 143.327 150.568C143.631 150.739 143.739 151.117 143.567 151.422C143.395 151.721 143.012 151.829 142.708 151.662C142.639 151.623 135.643 147.852 127.286 152.163C126.977 152.32 126.594 152.203 126.432 151.898C126.27 151.589 126.388 151.211 126.697 151.054C127.576 150.597 128.43 150.229 129.27 149.929Z"
          fill="#64C4F5"
        />
        <path
          d="M117.627 121.114C117.838 121.119 118.049 121.177 118.246 121.29C118.849 121.644 119.051 122.415 118.697 123.014C115.167 128.979 109.349 129.274 109.104 129.283C108.406 129.313 107.817 128.773 107.783 128.081C107.748 127.388 108.284 126.809 108.976 126.779C109.167 126.77 113.699 126.49 116.517 121.732C116.753 121.32 117.185 121.104 117.627 121.114Z"
          fill="#64C4F5"
        />
        <path
          d="M147.289 120.392C147.691 120.313 148.123 120.431 148.433 120.74C152.375 124.683 156.848 123.883 157.035 123.843C157.717 123.711 158.385 124.153 158.522 124.83C158.66 125.508 158.228 126.166 157.55 126.303C157.305 126.347 151.585 127.442 146.646 122.503C146.155 122.012 146.155 121.217 146.646 120.731C146.832 120.549 147.053 120.436 147.289 120.392Z"
          fill="#64C4F5"
        />
        <path
          opacity="0.2"
          d="M174.618 134.134L171.034 134.473C169.748 134.596 168.574 133.741 168.294 132.48L163.316 110.194C163.016 108.849 163.831 107.508 165.167 107.155L174.785 104.631L174.618 134.134Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M119.285 115.884C116.786 109.06 114.287 98.1453 116.929 88.041L91.3633 113.606C98.5856 116.022 110.104 117.073 119.285 115.884Z"
          fill="black"
        />
        <path
          d="M116.925 113.606C114.121 105.328 115.265 94.0751 116.925 88.041L91.3594 113.606C99.1021 115.015 107.748 114.789 116.925 113.606Z"
          fill="white"
        />
        <path
          d="M103.053 115.016C98.7866 115.016 94.8833 114.687 91.2844 114.034C91.1224 114.004 90.9947 113.891 90.9456 113.734C90.8965 113.577 90.9407 113.41 91.0536 113.292L116.619 87.7271C116.761 87.5847 116.977 87.5602 117.149 87.6584C117.321 87.7566 117.404 87.9628 117.35 88.1542C115.804 93.771 114.527 105.147 117.341 113.459C117.385 113.582 117.365 113.719 117.296 113.832C117.228 113.945 117.11 114.019 116.982 114.034C111.935 114.692 107.31 115.016 103.053 115.016ZM92.2664 113.317C99.0566 114.441 106.947 114.417 116.339 113.238C114.027 105.908 114.552 96.2014 116.108 89.475L92.2664 113.317Z"
          fill="#474747"
        />
        <path
          d="M204.751 150.951C204.427 150.951 204.128 150.735 204.044 150.401L195.04 116.091C194.937 115.698 195.172 115.301 195.565 115.198C195.958 115.094 196.356 115.33 196.459 115.723L205.463 150.032C205.566 150.425 205.331 150.823 204.938 150.926C204.879 150.946 204.815 150.951 204.751 150.951Z"
          fill="#474747"
        />
        <path
          d="M224.445 99.8153L223.914 99.9546L224.682 102.88L225.213 102.741L224.445 99.8153Z"
          fill="#38A6DE"
        />
        <path
          d="M223.05 96.6956L220.936 88.637C220.448 86.7706 218.757 85.4641 216.825 85.4641C216.462 85.4641 216.095 85.5135 215.743 85.6013L210.215 87.0506L210.078 86.5181L215.606 85.0689C216.001 84.9646 216.413 84.9097 216.825 84.9097C219.004 84.9097 220.909 86.3808 221.463 88.4888L223.577 96.5474L223.05 96.6956Z"
          fill="#38A6DE"
        />
        <path
          d="M224.179 117.748L171.842 131.483C170.305 131.883 168.73 130.966 168.329 129.429L161.703 104.183C161.303 102.646 162.219 101.071 163.756 100.67L216.093 86.9353C217.63 86.5346 219.206 87.4514 219.606 88.9884L226.232 114.235C226.633 115.772 225.716 117.342 224.179 117.748Z"
          fill="#38A6DE"
        />
        <path
          d="M169.667 110.713L176.851 108.72L177.364 110.57L172.868 111.818L173.25 113.197L177.423 112.042L177.916 113.814L173.744 114.969L174.219 116.68L178.845 115.395L179.394 117.359L172.079 119.389L169.667 110.713Z"
          fill="white"
        />
        <path
          d="M180.868 116.95L178.465 108.273L182.934 107.034C183.763 106.802 184.419 106.703 184.893 106.722C185.367 106.74 185.799 106.909 186.173 107.218C186.546 107.528 186.807 107.962 186.965 108.512C187.096 108.992 187.112 109.439 187.005 109.844C186.897 110.249 186.693 110.612 186.391 110.933C186.201 111.137 185.915 111.335 185.541 111.534C185.893 111.555 186.156 111.594 186.324 111.662C186.439 111.703 186.625 111.814 186.882 111.994C187.13 112.175 187.308 112.321 187.406 112.433L189.396 114.584L186.368 115.422L184.2 113.169C183.924 112.875 183.701 112.695 183.525 112.636C183.296 112.553 183.059 112.548 182.814 112.613L182.579 112.678L183.557 116.196L180.868 116.95ZM182.122 111.037L183.257 110.721C183.379 110.683 183.606 110.583 183.936 110.403C184.101 110.312 184.222 110.188 184.298 110.011C184.374 109.834 184.38 109.65 184.324 109.458C184.249 109.179 184.097 108.988 183.876 108.887C183.655 108.785 183.305 108.808 182.816 108.939L181.638 109.265L182.122 111.037Z"
          fill="white"
        />
        <path
          d="M190.286 114.332L187.883 105.664L192.352 104.426C193.181 104.193 193.837 104.086 194.311 104.113C194.785 104.132 195.217 104.3 195.591 104.61C195.964 104.919 196.225 105.353 196.383 105.903C196.514 106.383 196.53 106.83 196.423 107.235C196.315 107.64 196.111 108.003 195.809 108.315C195.619 108.52 195.333 108.717 194.959 108.916C195.31 108.937 195.574 108.977 195.742 109.044C195.856 109.086 196.042 109.197 196.3 109.377C196.548 109.557 196.726 109.703 196.824 109.815L198.814 111.966L195.785 112.805L193.618 110.552C193.341 110.258 193.119 110.077 192.943 110.019C192.714 109.935 192.477 109.93 192.232 109.995L191.997 110.061L192.975 113.578L190.286 114.332ZM191.549 108.428L192.684 108.112C192.806 108.075 193.033 107.974 193.363 107.794C193.528 107.712 193.649 107.579 193.725 107.402C193.801 107.225 193.807 107.041 193.751 106.849C193.676 106.57 193.524 106.379 193.303 106.278C193.082 106.176 192.732 106.199 192.243 106.33L191.065 106.656L191.549 108.428Z"
          fill="white"
        />
        <path
          d="M197.935 107.545C197.544 106.13 197.63 104.919 198.201 103.91C198.772 102.901 199.76 102.201 201.165 101.81C202.605 101.41 203.826 101.486 204.826 102.049C205.825 102.602 206.516 103.582 206.899 104.979C207.178 105.991 207.236 106.876 207.082 107.615C206.919 108.363 206.569 109 206.026 109.544C205.482 110.087 204.726 110.486 203.766 110.755C202.788 111.026 201.939 111.092 201.209 110.964C200.479 110.826 199.824 110.486 199.236 109.917C198.649 109.348 198.215 108.557 197.935 107.545ZM200.615 106.817C200.857 107.69 201.192 108.281 201.626 108.572C202.06 108.863 202.552 108.934 203.111 108.776C203.687 108.617 204.077 108.304 204.288 107.845C204.5 107.385 204.471 106.667 204.201 105.698C203.977 104.877 203.644 104.33 203.21 104.048C202.775 103.766 202.274 103.695 201.716 103.853C201.175 104.002 200.794 104.315 200.574 104.792C200.362 105.252 200.373 105.935 200.615 106.817Z"
          fill="white"
        />
        <path
          d="M209.603 108.977L207.199 100.3L211.669 99.0612C212.498 98.8285 213.145 98.7303 213.628 98.7487C214.101 98.7673 214.534 98.9357 214.907 99.2453C215.28 99.5549 215.542 99.989 215.7 100.539C215.83 101.019 215.846 101.466 215.739 101.871C215.632 102.276 215.427 102.639 215.125 102.96C214.936 103.164 214.65 103.362 214.276 103.561C214.627 103.581 214.891 103.621 215.058 103.688C215.173 103.73 215.359 103.841 215.616 104.021C215.865 104.201 216.042 104.348 216.141 104.46L218.131 106.611L215.102 107.449L212.935 105.196C212.658 104.902 212.436 104.722 212.268 104.663C212.039 104.579 211.802 104.574 211.558 104.64L211.322 104.705L212.3 108.223L209.603 108.977ZM210.857 103.064L211.983 102.748C212.105 102.71 212.331 102.61 212.662 102.43C212.827 102.339 212.947 102.215 213.024 102.038C213.091 101.861 213.105 101.677 213.05 101.485C212.975 101.206 212.823 101.015 212.602 100.914C212.381 100.812 212.031 100.835 211.542 100.966L210.363 101.292L210.857 103.064Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1066_76825"
            x1="133.26"
            y1="34"
            x2="133.26"
            y2="208.519"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E3F6FF" />
            <stop offset="1" stopColor="#F4FBFF" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}

export default NotFoundIcon
