import { Component, ErrorInfo } from "react"
import NotFound from "../../pages/notFound/NotFound"

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
  }

  render(): React.ReactNode {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return <NotFound onHomeClick={() => this.setState({ hasError: false })} />
    }
    return children
  }
}

export default ErrorBoundary
