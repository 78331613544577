import styled from "styled-components"

const NotFoundWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;

  .contentwrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .description {
      font-size: 14px;
      color: ${(props) => props.theme.textcolordarkgrey};
    }
  }
`
export default NotFoundWrapper
