function ExportDashboardIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8335 9.16668L17.6668 2.33334"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3325 5.66666V1.66666H14.3325"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1665 1.66666H7.49984C3.33317 1.66666 1.6665 3.33332 1.6665 7.49999V12.5C1.6665 16.6667 3.33317 18.3333 7.49984 18.3333H12.4998C16.6665 18.3333 18.3332 16.6667 18.3332 12.5V10.8333"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ExportDashboardIcon
