/* eslint-disable @typescript-eslint/no-explicit-any */
// PROJECT IMPORTS
import axios from "axios"
import { message } from "antd"
import {
  FETCH_CUSTOMER_RESELLER,
  RESELLER_CUSTOMERS,
  RESELLER_CUSTOMERS_USERS,
  SPECIFIC_CUSTOMERS_INFO
} from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"

// eslint-disable-next-line import/prefer-default-export
export const resellersList = (params: any) => {
  return apiInstance.get(FETCH_CUSTOMER_RESELLER, { params })
}

export const resllerCustomersGet = (params: any) => {
  return apiInstance.get(RESELLER_CUSTOMERS(params.reseller), {params})
}


export const specificCustomersGet = (params: any) => {
  return apiInstance.get(SPECIFIC_CUSTOMERS_INFO(params.reseller, params.domain))
}

export const resellerCustomerUsersGet = (params: any) => {
  return apiInstance.get(
    RESELLER_CUSTOMERS_USERS(params.reseller, params.domain),
    { params }
  )
}

export const resllerCustomersPost = (payload: any) => {
  return apiInstance.post(RESELLER_CUSTOMERS(payload.reseller), payload)
}

export const resllerCustomersPut = (payload: any) => {
  return apiInstance.put(SPECIFIC_CUSTOMERS_INFO(payload.reseller,payload.domain), payload)
}
