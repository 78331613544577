import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import CustomTheme from "../../../config/themeColor"
import { LoginValues } from "../../../interfaces/AuthInterface"

interface Theme {
  theme: unknown
  currentTheme: "SYSTEM_PREFRENCE" | "LIGHT" | "DARK"
  rememberUser?: LoginValues
}
const initialState: Theme = {
  theme: CustomTheme.Default,
  currentTheme: "SYSTEM_PREFRENCE",
  rememberUser: {
    email: "",
    password: "",
    remember: false
  }
}

export const themeSlice = createSlice({
  name: "themeSlice",
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<Theme>) => {
      state.theme =
        action.payload.theme === "default"
          ? CustomTheme.Default
          : CustomTheme.DarkMode
      state.currentTheme = action.payload.currentTheme
    },
    rememberUSER: (state, action: PayloadAction<LoginValues>) => {
      state.rememberUser = action.payload
    }
  }
})

export const { changeTheme, rememberUSER } = themeSlice.actions

export default themeSlice.reducer
