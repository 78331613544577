import SplashLoaderStyle from "./SplasLoader.style"
import SplashLogoIcon from "../Icons/SplashLogo"
import SplashFaviconIcon from "../Icons/SplashFavicon"

function SplashLoader() {
  return (
    <SplashLoaderStyle>
      <div className="inner">
        <span className="favicon">
          <SplashFaviconIcon />
        </span>
        <div className="loading">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <SplashLogoIcon />
      </div>
    </SplashLoaderStyle>
  )
}

export default SplashLoader
