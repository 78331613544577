import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Col, Layout, Row } from "antd"
import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import PageHeader from "../Header/Header"
import PageContent from "../../pages/dashboard/Dashboard.style"
import Sidebar from "../Sidebar/Sidebar"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import Loader from "../Loader/Loader"
import Support from "../../pages/support/support"

function ProtectedRoute() {
  const token = localStorage.getItem("accessToken")

  const navigate = useNavigate()

  const loacation = useLocation()

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0()

  function base64UrlDecode(base64Url: any) {
    // Add padding if needed and replace characters according to the JWT spec
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    // Decode base64 string
    const decoded = atob(base64)
    // Convert the decoded string to a Uint8Array
    const uint8Array = new Uint8Array(decoded.length)
    for (let i = 0; i < decoded.length; i += 1) {
      uint8Array[i] = decoded.charCodeAt(i)
    }
    return uint8Array
  }

  function decodeJwt(token: any) {
    const parts = token.split(".")
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format")
    }

    const header = JSON.parse(
      new TextDecoder().decode(base64UrlDecode(parts[0]))
    )
    const payload = JSON.parse(
      new TextDecoder().decode(base64UrlDecode(parts[1]))
    )

    return {
      header,
      payload
    }
  }

  const AuthHeader = async () => {
    try {
      const audience = import.meta.env?.VITE_AUTH0_AUDIENCE
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const accessToken: any = await getAccessTokenSilently({
        authorizationParams: {
          audience,
          scope: "read:current_user"
        }
      })
      const data = decodeJwt(accessToken)

      if (
        data?.payload?.["https://myonecloud.com/scope"] === "Reseller" ||
        data?.payload?.["https://myonecloud.com/scope"] === "Super User"
      ) {
        localStorage.setItem(
          "showTeams",
          data?.payload?.["https://myonecloud.com/scope"]
        )
      }
      localStorage.setItem("accessToken", accessToken)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message === "Login required" && !isAuthenticated) {
        // loginWithRedirect()
        const params = new URLSearchParams(window.location.search)
        const error = params.get("error")
        const errorDescription = params.get("error_description")

        if (error === "access_denied") {
          navigate(`/relogin${window.location.search}`)
        } else {
          loginWithRedirect()
        }
      } else if (
        error?.message === "We could not locate your OneCloud Account"
      ) {
        logout()
        localStorage.clear()
      } else {
        //navigate(`/relogin${window.location.search}`)
        const params = new URLSearchParams(window.location.search)
        const error = params.get("error")
        const errorDescription = params.get("error_description")

        if (error === "access_denied") {
          navigate(`/relogin${window.location.search}`)
        } else {
          logout()
          localStorage.removeItem("accessToken")
          localStorage.clear()
        }
      }
    }
  }

  useEffect(() => {
    AuthHeader()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, user?.sub, isAuthenticated])

  const paths = [
    "/billing",
    "/secure-fax",
    "/numbers",
    "/numbers/orders",
    "/numbers/inventory",
    "/customer",
    "/training/learning-track",
    "/training/tag-management",
    "/support",
    "/ucaas",
    "/partner/deals-pipeline",
    "/partner/resources",
    "/partner/accounts",
    "/partner/register-deal",
    "/partner/add-on-deal",
    "/partner/deal-details",
    "/partner/resource/new-asset",
    "/partner/resource/new-asset-edit",
    // "/partner/account",
    "/partner/account/details",
    "/partner/account/details/deals",
    "/partner/account/details/users",
    "/partner/account/details/representatives",
    "/customer/customer-details/summary",
    "/customer/customer-details/users",
    "/customer/customer-details/sites",
    "/customer/customer-details/orders",
    "/customer/customer-details/invoice"
  ]

  if (isLoading)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader />
      </div>
    )

  return !isLoading && token && isAuthenticated ? (
    <Layout>
      <PageHeader />
      <Row wrap={false}>
        <Col
          flex="none"
          style={{ backgroundColor: "#38a6de" }}
          className="bg-primary"
        >
          <Sidebar />
        </Col>
        <Col flex="auto">
          <PageContent
            style={{
              padding: paths?.includes(loacation.pathname) ? 0 : "0px"
            }}
          >
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
            <Support />
          </PageContent>
        </Col>
      </Row>
    </Layout>
  ) : // <Navigate to="/login" />
  null
}

export default ProtectedRoute
