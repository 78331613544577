/* eslint-disable @typescript-eslint/no-explicit-any */
// PROJECT IMPORTS
import {
  INVENTORY,
  INVENTORY_EXPORT,
  GET_CARRIERS,
  GET_REGION
} from "../../constants/ConstAPI"
import { apiInstance } from "../../services/api"

/* ============================== INVENTORY SERVICES ============================== */

export const inventoryListGet = (params: any) => {
  return apiInstance.get(INVENTORY, { params })
}

export const inventoryEdit = (payload: any) => {
  return apiInstance.put(INVENTORY, payload)
}

export const inventoryListExport = () => {
  return apiInstance.get(INVENTORY_EXPORT)
}

export const carriersListGet = () => {
  return apiInstance.get(GET_CARRIERS)
}

export const regionGet = () => {
  return apiInstance.get(GET_REGION)
}
