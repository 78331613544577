import { DefaultOptionType } from "antd/es/select"
import { getPartnerDetails } from "../pages/partner/partnerSlice"
import { decodeJwt } from "."
import { PartnerOnboardingRole } from "../types/partnerOnboarding"

const checkPartnerStatus = async (dispatch: any): Promise<boolean> => {
  try {
    const partnerDetails: any = await dispatch(getPartnerDetails()).unwrap()

    if (
      partnerDetails &&
      partnerDetails?.data &&
      partnerDetails?.data.length > 0
    ) {
      const partnerStatus = partnerDetails.data[0].partner_status
      return partnerStatus !== "onboarding"
    }
  } catch (error) {
    console.error("Error fetching partner details:", error)
  }

  return false
}

export const partnerContactTypeSelection = (
  isPartnerTSD: boolean = true
): DefaultOptionType[] => {
  return [
    { label: "Partner Admin", value: "Partner Admin" },
    { label: "Partner User", value: "Partner User" },
    ...(isPartnerTSD
      ? [{ label: "Partner Master Agent", value: "Partner TSD" }]
      : [])
  ]
}

export const getPartnerAccountInfo = () => {
  const token = localStorage.getItem("accessToken")
  const accessData = decodeJwt(token)
  const partner_account_id =
    accessData?.payload?.["https://onecloud.com/claims/partner_account_id"]
  const partner_lead_id =
    accessData?.payload?.["https://onecloud.com/claims/partner_lead_id"]

  const hasRole = (role: string) =>
    accessData?.payload?.["https://onecloud.com/claims/roles"]?.some(
      (r: string) => r === role
    )

  const isPartnerOnboard = hasRole(`Partner ${PartnerOnboardingRole.Onboard}`)
  const isPartnerOnboardTSD = hasRole(
    `Partner ${PartnerOnboardingRole.OnboardTSD}`
  )
  const totalOnboradingSteps = isPartnerOnboard ? 7 : 5

  return {
    partner_account_id,
    partner_lead_id,
    isPartnerOnboard,
    isPartnerOnboardTSD,
    totalOnboradingSteps
  }
}

export default checkPartnerStatus

export const getPartnerAccountType = (
  isPartnerTSD: boolean,
  isEdit: boolean,
  data: any
) => {
  if (isPartnerTSD) return "VAR - TSD"
  if (isEdit) return data?.partner_account_type
  return ""
}

export const getTitle = (isEdit: boolean, isExtraFormFieldVisible: any) => {
  if (isEdit) return "Edit Account"
  return isExtraFormFieldVisible ? "Add User" : "Add Account"
}

export const partnerAccountTypeOptions = [
  {
    value: "VAR - Bronze",
    label: "VAR - Bronze",
    title: "OneCloud Value Added Reseller - Bronze"
  },
  {
    value: "VAR - Silver",
    label: "VAR - Silver",
    title: "OneCloud Value Added Reseller - Silver"
  },
  {
    value: "VAR - Gold",
    label: "VAR - Gold",
    title: "OneCloud Value Added Reseller - Gold"
  },
  {
    value: "VAR - Platinum",
    label: "VAR - Platinum",
    title: "OneCloud Value Added Reseller - Platinum"
  },
  {
    value: "VAR - TSD",
    label: "VAR - TSD",
    title: "OneCloud Value Added Reseller - TSD"
  },
  {
    value: "VAR - TSD Referral",
    label: "VAR - TSD Referral",
    title: "VAR - TSD Referral"
  },
  {
    value: "TSD - Master Agent",
    label: "TSD - Master Agent",
    title: "TSD - Master Agent"
  },
  { value: "TSD - Referral", label: "TSD - Referral", title: "TSD - Referral" }
]

export const getDefaultFieldValue = (
  fieldValue: string | undefined,
  fallback: string = ""
) => {
  return fieldValue ?? fallback
}
