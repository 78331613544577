/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
// eslint-disable-next-line import/no-cycle
import { RootState } from "../../store/store"
import { useAppSelector } from "../../store/hooks"
import {
  resellerCustomerUsersGet,
  resellersList,
  resllerCustomersGet,
  resllerCustomersPost,
  resllerCustomersPut,
  specificCustomersGet
} from "./resellerService"

interface InitialState {
  resellersList: any
  resellerCustomerData: any
  resellerCustomerUsers: any
}

/* ============================== INVANTORY SLICE ============================== */

const initialState: InitialState = {
  resellersList: [],
  resellerCustomerData: {},
  resellerCustomerUsers: {}
}

export const getResellerList = createAsyncThunk(
  "resellers/list",
  async (params: any) => {
    return resellersList(params)
  }
)

export const getResellerCustomerList = createAsyncThunk(
  "reseller-customer/list",
  async (params: any) => {
    return await resllerCustomersGet(params)
  }
)

export const getCustomerDataInfo = createAsyncThunk(
  "customer-info/list",
  async (params: any) => {
    return await specificCustomersGet(params)
  }
)

export const getResellerCustomerUsersList = createAsyncThunk(
  "reseller-customer-user/list",
  async (params: any) => {
    return resellerCustomerUsersGet(params)
  }
)

export const createresellerCustomer = createAsyncThunk(
  "reseller-customer/create",
  async (params: any) => {
    return await resllerCustomersPost(params)
  }
)

export const updateresellerCustomer = createAsyncThunk(
  "reseller-customer/update",
  async (params: any) => {
    return await resllerCustomersPut(params)
  }
)

const resellerSlice = createSlice({
  name: "reseller",
  initialState,
  reducers: {
    clearOrdersSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(
      getResellerList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.resellersList = action.payload?.data
      }
    )
    builder.addCase(
      getResellerCustomerList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.resellerCustomerData = action.payload.data
      }
    )
    builder.addCase(
      getResellerCustomerUsersList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.resellerCustomerData = action.payload.data
      }
    )
  }
})

export default resellerSlice.reducer
export const { clearOrdersSlice } = resellerSlice.actions

export const selectResellerOptionList = (state: RootState) =>
  state.reseller.resellersList
export const useResellerOptionList = () => {
  const resellerOptionList = useAppSelector(selectResellerOptionList)
  return useMemo(() => resellerOptionList, [resellerOptionList])
}

export const selectresellerCustomerData = (state: RootState) =>
  state.reseller.resellerCustomerData
export const useResellerCustomerData = () => {
  const customerusersData = useAppSelector(selectresellerCustomerData)
  return useMemo(() => customerusersData, [customerusersData])
}

export const selectresellerCustomerUserData = (state: RootState) =>
  state.reseller.resellerCustomerUsers
export const useResellerCustomerUserData = () => {
  const customerusersData = useAppSelector(selectresellerCustomerUserData)
  return useMemo(() => customerusersData, [customerusersData])
}
