/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  inventoryEdit,
  inventoryListExport,
  inventoryListGet,
  carriersListGet,
  regionGet
} from "./inventoryService"
// eslint-disable-next-line import/no-cycle
import { RootState } from "../../store/store"
import { useAppSelector } from "../../store/hooks"

interface RegionData {
  id: number
  name: string
}

interface InitialState {
  inventoryData: any
  regionData: RegionData[]
  regionLoading: boolean
}

/* ============================== INVANTORY SLICE ============================== */

const initialState: InitialState = {
  inventoryData: {},
  regionData: [],
  regionLoading: true
}

export const getInventoryList = createAsyncThunk(
  "inventory-list/list",
  async (params: any) => {
    return await inventoryListGet(params)
  }
)

export const editInventory = createAsyncThunk(
  "inventory/edit",
  async (payload: any) => {
    return await inventoryEdit(payload)
  }
)

export const exportInventoryList = createAsyncThunk(
  "inventory-list/export",
  async () => {
    return await inventoryListExport()
  }
)

export const getCarriersList = createAsyncThunk(
  "inventory-list/carriers/list",
  async () => {
    return await carriersListGet()
  }
)

export const getRegionList = createAsyncThunk(
  "inventory-list/region/list",
  async () => {
    return await regionGet()
  }
)

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    clearInventorySlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(
      getInventoryList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.inventoryData = action.payload
      }
    )
    builder.addCase(getInventoryList.rejected, (state) => {
      state.inventoryData = {}
    })
    builder.addCase(
      getRegionList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.regionLoading = false
        const newData = action.payload?.data || []
        state.regionData = newData.map((val: any) => ({
          value: String(val?.id),
          label: val?.name
        }))
      }
    )
    builder.addCase(getRegionList.rejected, (state) => {
      state.regionLoading = false
      state.regionData = []
    })
  }
})

export default inventorySlice.reducer
export const { clearInventorySlice } = inventorySlice.actions

export const selectInventoryData = (state: RootState) =>
  state.inventory.inventoryData
export const useInventoryData = () => {
  const inventoryData = useAppSelector(selectInventoryData)
  return useMemo(() => inventoryData, [inventoryData])
}

export const selectRegion = (state: RootState) => ({
  regionData: state.inventory.regionData,
  regionLoading: state.inventory.regionLoading
})
export const useRegionList = () => {
  const regionData = useAppSelector(selectRegion)
  return useMemo(() => regionData, [regionData])
}
