import React from "react"

function FaxIcon() {
  return (
    <span className="anticon anticon-fax ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          d="M3.64384 17.6197H5.69566V20.9004C5.69566 21.1785 5.80619 21.4454 6.00292 21.6419C6.19964 21.8387 6.46635 21.9492 6.74449 21.9492H16.5208C16.7989 21.9492 17.0656 21.8387 17.2624 21.6419C17.4589 21.4454 17.5695 21.1785 17.5695 20.9004V17.6197H19.6214C20.0586 17.6197 20.4778 17.446 20.787 17.1369C21.0962 16.8277 21.2698 16.4085 21.2698 15.9713V8.5889C21.2698 8.15173 21.0961 7.73251 20.787 7.42334C20.4779 7.11417 20.0586 6.94054 19.6214 6.94054H17.565V3.04883C17.5654 2.77084 17.4556 2.50414 17.2592 2.30725C17.0629 2.11053 16.7964 2 16.5184 2H6.74902C6.47088 2 6.20401 2.11053 6.00745 2.30725C5.81072 2.50382 5.70019 2.77068 5.70019 3.04883V6.94514H3.64837C3.2112 6.94514 2.79182 7.1188 2.48281 7.42795C2.17365 7.73694 2 8.15633 2 8.5935V15.971C2 16.4074 2.17303 16.826 2.48106 17.135C2.78926 17.444 3.20737 17.6181 3.64377 17.6194L3.64384 17.6197ZM16.8306 20.8959C16.8306 21.0646 16.6938 21.2014 16.5251 21.2014H6.75329C6.67187 21.2021 6.59363 21.1701 6.5359 21.1127C6.47801 21.0554 6.44557 20.9773 6.44557 20.8959V12.6586H16.8305L16.8306 20.8959ZM6.44363 3.04879C6.44363 2.88006 6.5804 2.74329 6.74913 2.74329H16.5185C16.6872 2.74329 16.824 2.88006 16.824 3.04879V6.9451H6.44395L6.44363 3.04879ZM2.74564 8.58894C2.74564 8.34705 2.84169 8.11517 3.01265 7.94406C3.18377 7.7731 3.41564 7.67704 3.65754 7.67704H19.6307C19.8726 7.67704 20.1044 7.7731 20.2756 7.94406C20.4465 8.11518 20.5426 8.34704 20.5426 8.58894V15.9713C20.5426 16.2132 20.4465 16.4451 20.2756 16.6162C20.1044 16.7872 19.8726 16.8832 19.6307 16.8832H17.5652V12.2823C17.5639 12.0776 17.3982 11.9119 17.1935 11.9108H6.07656C5.87363 11.9145 5.71078 12.0794 5.70951 12.2823V16.8808H3.65768C3.41579 16.8808 3.18391 16.7847 3.0128 16.6138C2.84184 16.4427 2.74578 16.2108 2.74578 15.9689L2.74564 8.58894Z"
          fill="currentcolor"
          stroke="currentcolor"
          strokeWidth="0.5"
        />
        <path
          d="M4.83651 10.1506C5.03944 10.1469 5.20229 9.98198 5.20356 9.7789C5.20212 9.68125 5.16396 9.58758 5.09637 9.51681C5.02735 9.447 4.93336 9.40771 4.83539 9.40771C4.73727 9.40771 4.64328 9.447 4.57426 9.51681C4.50508 9.58663 4.46596 9.68061 4.46484 9.7789C4.4685 9.98262 4.63278 10.1469 4.8365 10.1506H4.83651Z"
          fill="currentcolor"
          stroke="currentcolor"
          strokeWidth="0.5"
        />
        <path
          d="M14.5008 16.5595H8.77381C8.67044 16.5514 8.5685 16.5867 8.49248 16.6571C8.4163 16.7274 8.37305 16.8263 8.37305 16.9299C8.37305 17.0336 8.4163 17.1325 8.49248 17.2028C8.5685 17.2731 8.67044 17.3085 8.77381 17.3004H14.5057C14.7101 17.3004 14.8761 17.1353 14.8772 16.931C14.8748 16.725 14.7071 16.5595 14.5011 16.5595L14.5008 16.5595Z"
          fill="currentcolor"
          stroke="currentcolor"
          strokeWidth="0.5"
        />
        <path
          d="M14.5008 18.853H8.77381C8.67044 18.8448 8.5685 18.8801 8.49248 18.9506C8.4163 19.0209 8.37305 19.1198 8.37305 19.2233C8.37305 19.327 8.4163 19.4259 8.49248 19.4962C8.5685 19.5665 8.67044 19.602 8.77381 19.5939H14.5057C14.6089 19.602 14.711 19.5665 14.7871 19.4962C14.8631 19.4259 14.9063 19.327 14.9063 19.2233C14.9063 19.1198 14.8631 19.0209 14.7871 18.9506C14.711 18.8801 14.6089 18.8448 14.5057 18.853H14.5008Z"
          fill="currentcolor"
          stroke="currentcolor"
          strokeWidth="0.5"
        />
        <path
          d="M14.501 14.2659H8.774C8.56869 14.2659 8.40234 14.4322 8.40234 14.6374C8.40234 14.8427 8.56869 15.009 8.774 15.009H14.5059C14.7111 15.009 14.8774 14.8427 14.8774 14.6374C14.8774 14.4322 14.7111 14.2659 14.5059 14.2659H14.501Z"
          fill="currentcolor"
          stroke="currentcolor"
          strokeWidth="0.5"
        />
      </svg>
    </span>
  )
}

export default FaxIcon
