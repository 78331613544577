import {
  getPartnerLeadOnboardingData,
  getPartnerOnboardingData
} from "../pages/partner/partnerSlice"
import { useAppDispatch } from "../store/hooks"
import { useEffect, useCallback, useState } from "react"
import { getPartnerAccountInfo } from "../utils/partnerUtils"

const usePartnerStatus = () => {
  const dispatch = useAppDispatch()
  const {
    isPartnerOnboard,
    isPartnerOnboardTSD,
    partner_account_id,
    partner_lead_id
  } = getPartnerAccountInfo()

  const [loadingPartnerOnboarding, setLoadingPartnerOnboarding] = useState(true)

  const fetchPartnerStatus = useCallback(async () => {
    try {
      if (isPartnerOnboard && partner_lead_id) {
        await dispatch(getPartnerLeadOnboardingData(partner_lead_id)).unwrap()
      }
      if (isPartnerOnboardTSD && partner_account_id) {
        await dispatch(getPartnerOnboardingData(partner_account_id)).unwrap()
      }
    } catch (error: any) {
      console.error("Error fetching partner status:", error)
    } finally {
      setLoadingPartnerOnboarding(false)
    }
  }, [dispatch])

  useEffect(() => {
    fetchPartnerStatus()
  }, [fetchPartnerStatus])

  return { loadingPartnerOnboarding, fetchPartnerStatus }
}

export default usePartnerStatus
