import { useNavigate } from "react-router-dom";
import ocManagelogoIcon from "../../images/Logo_oc_manage.png";
import BrandLogoIcon from "../Icons/BrandLogo";

interface LogoProps {
  logoIcon?: boolean;
  style?: Record<string, string>;
}

function BrandLogo(props: LogoProps) {
  const { logoIcon, style } = props;
  const navigate = useNavigate();
  return (
    <div
      className="brandlogo"
      style={{
        width: "100px",
        textAlign: "center",
        ...style,
        cursor: "pointer",
      }}
      onClick={() => navigate("/dashboard")}
    >
      {!logoIcon ? (
        <BrandLogoIcon />
      ) : (
        <img src={ocManagelogoIcon} alt="logo" style={{ height: "52px" }} />
      )}
    </div>
  );
}

export default BrandLogo;
