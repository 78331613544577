/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  addUserNumber,
  addUserEmail,
  domainGet,
  domainListGet,
  domainNmbersGet,
  domainSitesGet,
  licenseListGet,
  logListGet,
  logUuidGet,
  removeUserNumber,
  removeUserEmail,
  secureFaxCreate,
  secureFaxListGet,
  secureFaxListNumberDelete,
  secureFaxListNumberGet,
  secureFaxNumberCreate,
  secureFaxNumberUpdate,
  secureFaxUpdate,
  timeZoneListGet,
  userOptionGet
} from "./secureFaxService"
// eslint-disable-next-line import/no-cycle
import { RootState } from "../../store/store"
import { useAppSelector } from "../../store/hooks"

interface InitialState {
  secureFaxData: any
  secureFaxNumberData: any
  domainList: any
  domainOptions: any
  userOptions: any
  timeZoneList: any
  domainSites: any
  domainNumbers: any
  licenseList: any
  logListData: any
}

/* ============================== INVANTORY SLICE ============================== */

const initialState: InitialState = {
  secureFaxData: {},
  secureFaxNumberData: {},
  logListData: {},
  domainList: [],
  domainOptions: [],
  userOptions: [],
  domainNumbers: [],
  timeZoneList: [],
  domainSites: [],
  licenseList: []
}

export const getSecureFaxList = createAsyncThunk(
  "secureFax-list/list",
  async (params: any) => {
    return await secureFaxListGet(params)
  }
)

export const getSecureFaxNumberList = createAsyncThunk(
  "secureFax-list-number/list",
  async (params: any) => {
    return await secureFaxListNumberGet(params)
  }
)

export const secureFaxNumberDelete = createAsyncThunk(
  "secureFax-list-number/delete",
  async (number: any) => {
    return await secureFaxListNumberDelete(number)
  }
)

export const createSecureFax = createAsyncThunk(
  "secureFax/create",
  async (payload: any) => {
    return await secureFaxCreate(payload)
  }
)

export const updateSecureFax = createAsyncThunk(
  "secureFax/update",
  async (payload: any) => {
    return await secureFaxUpdate(payload)
  }
)

export const createSecureFaxNumber = createAsyncThunk(
  "secureFax-number/create",
  async (payload: any) => {
    return await secureFaxNumberCreate(payload)
  }
)

export const updateSecureFaxNumber = createAsyncThunk(
  "secureFax-number/update",
  async (payload: any) => {
    const { did, ...rest } = payload
    return await secureFaxNumberUpdate(did, rest)
  }
)

export const userNumberAdd = createAsyncThunk(
  "secureFax-number/add",
  async (payload: any) => {
    const { id, number } = payload
    return await addUserNumber(id, number)
  }
)

export const userNumberRemove = createAsyncThunk(
  "secureFax-number/remove",
  async (payload: any) => {
    const { id, number } = payload
    return await removeUserNumber(id, number)
  }
)

export const userEmailAdd = createAsyncThunk(
  "secureFax-email/add",
  // async (payload: any) => {
  //   const { number, email } = payload
  //   return await addUserEmail(number, email)
  // }
  async (payload: any) => {
    return await addUserEmail(payload)
  }
)

export const userEmailRemove = createAsyncThunk(
  "secureFax-email/remove",
  // async (payload: any) => {
  //   const { number, email } = payload
  //   return await removeUserEmail(number, email)
  // }
  async (payload: any) => {
    return await removeUserEmail(payload)
  }
)

export const getDomainList = createAsyncThunk(
  "domain-list/list",
  async (payload: any) => {
    return await domainListGet(payload)
  }
)

export const getDomain = createAsyncThunk("domain/list", async () => {
  return await domainGet()
})

export const getLicenseList = createAsyncThunk(
  "license-list/list",
  async () => {
    return await licenseListGet()
  }
)

export const getTimeZoneList = createAsyncThunk(
  "timeZone-list/list",
  async () => {
    return await timeZoneListGet()
  }
)

export const getDomainSites = createAsyncThunk(
  "domain-sites-list/list",
  async (params: any) => {
    return await domainSitesGet(params)
  }
)

export const getDomainNumbers = createAsyncThunk(
  "domain-number-list/list",
  async (domain: string) => {
    return await domainNmbersGet(domain)
  }
)

export const getUserOption = createAsyncThunk(
  "domain-user-list/options",
  async (domain: string) => {
    return await userOptionGet(domain)
  }
)

export const getLogList = createAsyncThunk(
  "log-list/list",
  async (params: any) => {
    return await logListGet(params)
  }
)

export const getLogUuid = createAsyncThunk(
  "log-list/uuid",
  async (uuid: any) => {
    return await logUuidGet(uuid)
  }
)

const secureFaxSlice = createSlice({
  name: "secureFax",
  initialState,
  reducers: {
    clearSecureFaxSlice: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSecureFaxList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.secureFaxData = action.payload
      }
    )

    builder.addCase(getSecureFaxList.rejected, (state) => {
      state.secureFaxData = []
    })

    builder.addCase(
      getSecureFaxNumberList.fulfilled,
      (state, action: PayloadAction<any>) => {
        let data = action.payload
        if (data?.fax_numbers?.length) {
          data?.fax_numbers?.map((x: any, idx: number) => {
            x.key = idx + 1
          })
        }
        state.secureFaxNumberData = data
      }
    )

    builder.addCase(
      getDomainList.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newData = action.payload?.data || []
        state.domainList = newData.map((val: any) => ({
          value: val?.domain,
          label: val?.domain
        }))
      }
    )

    builder.addCase(
      getDomain.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newData = action.payload?.domains || []
        state.domainOptions = newData.map((val: any) => ({
          value: val?.domain_id,
          label: val?.domain
        }))
      }
    )

    builder.addCase(
      getUserOption.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newData = action.payload?.users || []
        state.userOptions = newData.map((val: any) => ({
          value: val?.id,
          label: val?.username
        }))
      }
    )

    builder.addCase(
      getTimeZoneList.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newData = action.payload || []
        state.timeZoneList = newData.map((val: any) => ({
          value: val,
          label: val
        }))
      }
    )

    builder.addCase(
      getDomainSites.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newData = action?.payload || []
        state.domainSites = newData.map((val: any) => ({
          value: val,
          label: val
        }))
      }
    )

    builder
      .addCase(
        getDomainNumbers.fulfilled,
        (state, action: PayloadAction<any>) => {
          const newData = action?.payload || []
          state.domainNumbers = newData.map((val: any) => ({
            value: val?.did,
            label: val?.did
          }))
        }
      )
      .addCase(
        getDomainNumbers.rejected,
        (state, action: PayloadAction<any>) => {
          state.domainNumbers = []
        }
      )

    builder
      .addCase(
        getLicenseList.fulfilled,
        (state, action: PayloadAction<any>) => {
          const newData = action?.payload || []
          state.licenseList = newData.map((val: any) => ({
            value: val?.id || "",
            label: `${val?.name || ""} ( ${val?.description || ""} )`,
            license_type: val?.license_type || ""
          }))
        }
      )

      .addCase(getLicenseList.rejected, (state, action: PayloadAction<any>) => {
        state.licenseList = []
      })

    builder.addCase(
      getLogList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.logListData = action.payload
      }
    )
  }
})

export default secureFaxSlice.reducer
export const { clearSecureFaxSlice } = secureFaxSlice.actions

export const selectSecureFaxData = (state: RootState) =>
  state.secureFax.secureFaxData
export const useSecureFaxData = () => {
  const secureFaxData = useAppSelector(selectSecureFaxData)
  return useMemo(() => secureFaxData, [secureFaxData])
}

export const selectSecureFaxNumberData = (state: RootState) =>
  state.secureFax.secureFaxNumberData
export const useSecureFaxNumberData = () => {
  const secureFaxNumberData = useAppSelector(selectSecureFaxNumberData)
  return useMemo(() => secureFaxNumberData, [secureFaxNumberData])
}

export const selectDomainList = (state: RootState) => state.secureFax.domainList
export const useDomainList = () => {
  const domainList = useAppSelector(selectDomainList)
  return useMemo(() => domainList, [domainList])
}

export const selectDomain = (state: RootState) => state.secureFax.domainOptions
export const useDomain = () => {
  const domainOptions = useAppSelector(selectDomain)
  return useMemo(() => domainOptions, [domainOptions])
}

export const selectUserOption = (state: RootState) =>
  state.secureFax.userOptions
export const useUserOption = () => {
  const userOptions = useAppSelector(selectUserOption)
  return useMemo(() => userOptions, [userOptions])
}

export const selectDomainNumber = (state: RootState) =>
  state.secureFax.domainNumbers
export const useDomainNumber = () => {
  const domainNumbers = useAppSelector(selectDomainNumber)
  return useMemo(() => domainNumbers, [domainNumbers])
}

export const selectTimeZoneList = (state: RootState) =>
  state.secureFax.timeZoneList
export const useTimeZoneList = () => {
  const timeZoneList = useAppSelector(selectTimeZoneList)
  return useMemo(() => timeZoneList, [timeZoneList])
}

export const selectDomainSites = (state: RootState) =>
  state.secureFax.domainSites
export const useDomainSites = () => {
  const domainSites = useAppSelector(selectDomainSites)
  return useMemo(() => domainSites, [domainSites])
}

export const selectLicenseList = (state: RootState) =>
  state.secureFax.licenseList
export const useLicenseList = () => {
  const licenseList = useAppSelector(selectLicenseList)
  return useMemo(() => licenseList, [licenseList])
}

export const selectLogListData = (state: RootState) =>
  state.secureFax.logListData
export const useLogListData = () => {
  const logListData = useAppSelector(selectLogListData)
  return useMemo(() => logListData, [logListData])
}
